<!--
 * @Description: 日月周切换选择器
 * @Author: luocheng
 * @Date: 2021-07-19 10:17:52
 * @LastEditors: luocheng
 * @LastEditTime: 2021-07-21 09:21:48
-->
<template>
  <div class="picker">
    <a href="javascript:;" class="btn" @click="onChange('prev')">
      <img src="@/assets/images/todos/prev.png" alt="">
    </a>
    <section class="date">
      <p class="label">{{ dateString }}</p>
      <a href="javascript:;" class="back-to" @click="onBackTarget">{{ backString }}</a>
    </section>
    <a href="javascript:;" class="btn" @click="onChange('next')">
      <img src="@/assets/images/todos/next.png" alt="">
    </a>
  </div>
</template>

<script>
import eventBus from '@/plugins/eventBus';

export default {
  name: 'DatePicker',
  props: {
    // 绑定的时间值
    // 日返回当日 00:00:00  时间戳
    // 周返回当周第一天 00:00:00 时间戳
    // 月返回当月第一天 00:00:00 时间戳
    value: {
      type: [Number, String],
      default: '',
      required: true
    },
    // 类型 date week month 日、周、月
    type: {
      type: String,
      default: 'date',
      required: true
    }
  },
  data() {
    return {
      timestamp: ''
    }
  },
  computed: {
    // 显示的文本
    dateString() {
      if (!this.timestamp || isNaN(this.timestamp)) return '';
      const dateObj = this.getSign(this.timestamp, this.type === 'week');
      if (!dateObj) return '';
      if (this.type === 'date') {
        return `${dateObj.year}年${dateObj.month}月${dateObj.day}日`;
      }
      if (this.type === 'week') {
        return `${dateObj.year}年${dateObj.month}月 第${dateObj.weekSort}周`;
      }
      if (this.type === 'month') {
        return `${dateObj.year}年${dateObj.month}月`;
      }
      return '';
    },
    // 回到当前文字
    backString() {
      if (this.type === 'date') return '回到今天';
      if (this.type === 'week') return '回到本周';
      if (this.type === 'month') return '回到本月';
      return '回到当前';
    }
  },
  created() {
    if (this.value && (typeof this.value === 'string' || typeof this.value === 'number')) {
      this.timestamp = this.value;
    } else {
      const now = new Date();
      const { year, month, day } = this.getSign(now.getTime());
      if (this.type === 'date') {
        // 日
        this.timestamp = new Date(`${year}/${month}/${day} 00:00:00`).getTime();
      } else if (this.type === 'week') {
        // 周
        this.timestamp = this.getMonday(now.getTime());
      } else if (this.type === 'month') {
        // 月
        this.timestamp = new Date(`${year}/${month}/1 00:00:00`).getTime();
      }
    }
    this.emitData();
  },
  mounted() {
    eventBus.$on('calendarChange', times => {
      if (!times || isNaN(times)) return;
      this.timestamp = times;
      this.emitData();
    });
  },
  methods: {
    /**
     * @desc: 切换日、周、月
     * @param {*} changeType 类型 prev上一个单位 next 下一个单位
     * @return {*}
     */
    onChange(changeType) {
      // 切换的时候需要结合当前所在的计量单位(日、周、月)
      if (this.type === 'list') return;
      // 日
      if (this.type === 'date') {
        if (changeType === 'prev') {
          this.timestamp = this.timestamp - 86400000;
        } else {
          this.timestamp = this.timestamp + 86400000;
        }
      } else if (this.type === 'week') {
        // 周
        if (changeType === 'prev') {
          this.timestamp = this.timestamp - 86400000 * 7;
        } else {
          this.timestamp = this.timestamp + 86400000 * 7;
        }
      } else if (this.type === 'month') {
        // 月
        let { year, month } = this.getSign(this.timestamp);
        if (changeType === 'prev') {
          if (month === 1) {
            month = 12;
            year = year - 1;
          } else {
            month = month - 1;
          }
          const days = this.getDays(year, month);
          this.timestamp = this.timestamp - 86400000 * days;
        } else {
          if (month === 12) {
            month = 1;
            year = year + 1;
          } else {
            month = month + 1;
          }
          const days = this.getDays(year, month);
          this.timestamp = this.timestamp + 86400000 * days;
        }
      }
      this.emitData();
    },
    /**
     * @desc: 返回到当前年月日
     * @param {*}
     * @return {*}
     */
    onBackTarget() {
      const target = new Date();
      if (this.type === 'date') {
        this.timestamp = this.getSign(target.getTime()).timestampZero;
      } else if (this.type === 'week') {
        this.timestamp = this.getMonday(target.getTime());
      } else if (this.type === 'month') {
        const { year, month } = this.getSign(target.getTime());
        this.timestamp = new Date(`${year}/${month}/1 00:00:00`).getTime();
      }
      this.emitData();
    },
    /**
     * @desc: 根据一周中的某一天返回当周一 00:00:00 时间戳
     * @param {*} times 某一周的某一天时间戳
     * @return {*} 当周一 00:00:00 时间戳
     */
    getMonday(times) {
      if (!times || isNaN(times)) return;
      const date = new Date(times);
      const weekDay = date.getDay() === 0 ? 7 : date.getDay(); // 周几
      // 非周一时候日期需要根据周几往前推周几 - 1 天
      // 月份第一天或不够减需要将月份向前推一个月
      // 如果月份为1月的时候年也需要往前倒推一年
      const lessCount = weekDay - 1;
      const { year, month, day } = this.getSign(times - lessCount * 86400000)
      return new Date(`${year}/${month}/${day} 00:00:00`).getTime();
    },
    /**
     * @desc: 根据时间戳返回相关日期对象
     * @param {*} times
     * @return {*}
     */
    getSign(times, needSort = false) {
      if (!times || isNaN(times)) return null;
      const dateObj = new Date(times);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1;
      const day = dateObj.getDate();
      const weekDay = dateObj.getDay();
      const hours = dateObj.getHours();
      const minutes = dateObj.getMinutes();
      const seconds = dateObj.getSeconds();
      return {
        year,
        month,
        day,
        weekDay,
        hours,
        weekSort: needSort ? this.getWeekSort(times) : '',
        minutes,
        seconds,
        // 零点的时间
        timestampZero: new Date(`${year}/${month}/${day} 00:00:00`).getTime()
      };
    },
    /**
     * @desc: 根据时间戳返回当前为全年第几周
     * @param {*} times 时间戳
     * @return {*} 
     */
    getWeekSort(times) {
      if (!times || isNaN(times)) return '';
      const date = new Date(times);
      // // 本年第一天为周几
      const yearStart = new Date(`${date.getFullYear()}/1/1 00:00:00`);
      const dayCount = Math.round((date - yearStart) / 86400000)
      return Math.ceil((dayCount + yearStart.getDay()) / 7);
    },
    /**
     * @desc: 判断一个月有几天
     * @param {*} year 年
     * @param {*} month 月
     * @return {*}
     */
    getDays(year, month) {
      if (month === 2) {
        // 2月需要判断平年 闰年
        if (this.isLeapYear(year)) return 29;
        return 28;
      } 
      // 大月 31天
      const LARGES = [1, 3, 5, 7, 8, 10, 12];
      const SMALLS = [4, 6, 9, 11];
      if (LARGES.includes(month)) return 31;
      if (SMALLS.includes(month)) return 30
    },
    /**
     * @desc: 判断是够为闰年 闰年能被4整除且不能被100整除，或能被400整除
     * @param {*} year 年份 number
     * @return {*}
     */
     isLeapYear(year) {
      if (isNaN(year)) return false;
      return (year % 4 === 0) && (year % 100 !== 0) || (year % 400 === 0);
    },
    /**
     * @desc: 提交数据v-model
     * @param {*}
     * @return {*}
     */
    emitData(){
      this.$emit('input', this.timestamp);
    }
  },
  beforeDestroy() {
    eventBus.$off('calendarChange');
  }
}
</script>

<style lang="less" scoped>
@pickerHeight: 20px;
@imgSize: 16px;
.picker{
  margin-top: 12px;
  width: 186px;
  display: flex;
  height: @pickerHeight;
  border: 1px solid #eaebed;
  border-radius: 4px;
  .btn{
    box-sizing: border-box;
    padding: calc((@pickerHeight - @imgSize)/2);
    &:hover{
      background: rgba(17, 111, 217, 0.05);
    }
    img{
      display: block;
      height: @imgSize;
      width: @imgSize;
    }
  }
  .date{
    position: relative;
    flex: 1;
    border: 1px solid #eaebed;
    border-top: 0;
    border-bottom: 0;
    overflow: hidden;
    &:hover{
      .back-to{
        display: block;
      }
    }
    .label{
      width: 100%;
      box-sizing: border-box;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      position: relative;
      text-align: center;
      padding: 0 8px;
      line-height: @pickerHeight;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: center;
      color: #525966;
    }
    .back-to{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: @pickerHeight;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #202126;
      line-height: @pickerHeight;
      display: none;
      text-decoration: none;
      background: #fff;
    }
  }
}
</style>