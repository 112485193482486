<!--
 * @Description: 待办主体区域
 * @Author: luocheng
 * @Date: 2021-07-15 15:45:49
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2023-08-11 14:55:39
-->
<template>
  <div class="main-box">
    <Header
      @getType="onGetType"
      @dateChange="onDate"
      @searchParamsChange="searchParamsChange"
    ></Header>
    <article class="content">
      <!-- 列表 -->
      <ListViewer
        v-if="currentTab === 'list' && !loadingFlag"
        @refresh="searchData"
      ></ListViewer>
      <!-- 日日程视图 -->
      <!-- 向内传递store中的内容  todoList-->
      <DayView
        v-else-if="currentTab === 'date' && !loadingFlag"
        :time="currentTime"
        :todoList="$store.getters.getActiveItem"
      ></DayView>
      <!-- 周视图 -->
      <WeekView
        v-else-if="currentTab === 'week' && !loadingFlag"
        :timestamp="currentTime"
      ></WeekView>
      <!-- 月视图 -->
      <MonthView
        v-else-if="currentTab === 'month' && !loadingFlag"
        :timestamp="currentTime"
      ></MonthView>
      <!-- loading 效果，1 是避免监听时重复计算，二是处理卡顿的视觉效果 -->
      <div v-if="loadingFlag" class="loding-div">
        <div class="active" v-loading="loadingFlag" style="text-align: center">
          <!-- <p>加载中....</p> -->
        </div>
      </div>
    </article>
    <!-- 保证计算属性getter -->
    <div style="visibility: hidden; height: 0; width: 0">
      {{ activeTask }} {{ activeIndex }}{{ page }} {{ refeshFlag }}
    </div>
  </div>
</template>

<script>
//import {Loading} from'element-ui';
import Header from './main/Header';
import DayView from './main/dayView/Index';
import WeekView from './main/weekView/Index';
import MonthView from './main/monthView/Index';
import ListViewer from './main/ListView/index';

import { getModulNameFun } from './main/modulAbout';
import { getNewHomeList, newReadCC, getFinishedList } from '@/apis/home';
import { format, addDays, endOfMonth, startOfMonth, getDay } from "date-fns";
import { toTurn } from './main/modulAbout'
import eventBus from '@/plugins/eventBus';


export default {
  name: 'MainBox',
  components: {
    Header,
    DayView,
    WeekView,
    MonthView,
    ListViewer,
    // 'v-loading':Loading
  },
  data () {
    return {
      // 当前视图类型
      currentTab: 'list',
      currentTime: 0,
      // 存储旧值,部分情况下会触发computed,但是值并没有改变,因此不需要刷新内容,例如组件重新挂载,
      oldActiveIndex: -1,
      oldPage: 0,
      oldrefeshFlag: 0
    }
  },
  created () {
    getModulNameFun()
  },
  mounted() {
		if (this.oldrefeshFlag !== this.$store.getters.getRefesh) {
			this.oldrefeshFlag = this.$store.getters.getRefesh;
			// 刷新数据
			this.searchData();
		}

		if (
			this.$store.getters.getActivetaskData.id ||
			this.$store.getters.getActivetaskData.msg_id
		) {
			this.dealActiveTask(this.$store.getters.getActivetaskData);
		}

		if (
			this.$store.getters.getActiveIndex === 4 &&
			this.oldActiveIndex !== this.$store.getters.getActiveIndex
		) {
			this.oldActiveIndex = this.$store.getters.getActiveIndex;
			this.getFinishedList();
		}
		this.oldActiveIndex = this.$store.getters.getActiveIndex;

		if (
			this.oldPage !== this.$store.getters.getFinishSearchParams.page &&
			this.activeIndex === 4
		) {
			this.oldPage = this.$store.getters.getFinishSearchParams.page;
			this.getFinishedList();
		}
    // 全局弹窗处理流程后刷新列表状态
    eventBus.$on('updateTodoList', () => {
      this.searchData();
    })
    // this.onGetType('list')
	},

  computed: {
    refeshFlag () {
      const data = this.$store.getters.getRefesh;

      return data;
    },
    loadingFlag () {
      return this.$store.getters.getSearchLoding;
    },
    activeTask () {
      const data = this.$store.getters.getActivetaskData;
      // if (data.id || data.msg_id) {
      //   this.dealActiveTask(data)
      // }
      return data;
    },
    activeIndex () {
      const data = this.$store.getters.getActiveIndex;
      if (data === 4 && this.oldActiveIndex !== data) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.oldActiveIndex = data
        this.getFinishedList()
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.oldActiveIndex = data;
      return data;
    },
    // 已完成分页
    page () {
      const data = this.$store.getters.getFinishSearchParams
      if (this.oldPage !== data.page && this.activeIndex === 4) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.oldPage = data.page
        this.getFinishedList()
      }
      return data;
    }
  },
  methods: {
    /**
     * @desc: 获取当前所选视图类型
     * @param {*} type date 日 week 周 month 月
     * @return {*}
     */
    onGetType (type) {
      console.log(type, '获取当前所选视图类型');
      this.currentTab = type;
      this.searchData();
      this.$emit('getType', this.currentTab);
    },
    /**
     * @desc: 选择时间
     * @param {*} times
     * @return {*}
     */
    onDate (times) {
      console.log(times, '------时间变化')
      this.currentTime = times;
      this.$emit('dateChange', times);
      this.searchData();
    },
    // 标记抄送已读
    newReadCC (id) {
      newReadCC({ id }).then(res => {
        if (res.data.code === 200) {
          this.searchData()
        }
      })
    },
    // 捕捉来自视图的活动任务改变
    dealActiveTask (item) {
      if ('id' in item) {
        if (item.module_name == 'assignment') {
          // 如果是任务
          this.$store.commit('setOpenDrawer', true)
          this.$store.commit('setDrewerData', { ...item, type: 'details' })
        } else {
          // 如果该待办不属于当前架构
          /* const archiId = localStorage.getItem('archi').split('/')[2]
          if (archiId != item.archi_id) {
            this.$Message.info(`"${item.title}", 该待办不属于当前组织架构，请切换至组织架构："${item.archi_name}" 后查看！`)
            // 清除活动任务内容
            this.$store.commit('setActivetaskData', {})
            return
          } */
          // 处理跳转,modulAbout.js
          toTurn(this, item.module_name, item)
        }
      }
      // 如果是抄送读抄送
      if (item.msg_type === 3) {
        this.newReadCC(item.msg_id)
      }
      // 清除活动任务内容
      this.$store.commit('setActivetaskData', {})
    },
    // 捕获筛选字段改变
    searchParamsChange () {
      // 传递筛选字段改变
      // this.$emit('searchParamsChange', res) //暂时使用不上
      this.searchData()
    },
    // 这里处理已完成任务检索
    getFinishedList () {
      switch (this.currentTab) {
        /** 列表 */
        case 'list': {
          this.searchFinishBuyList()
          break
        }
        /** 日 */
        case 'date': {
          this.searchFinishBuyDay()
          break
        }
        /** 周 */
        case 'week': {
          this.searchFinishBuyWeek()
          break
        }
        /** 月 */
        case 'month': {
          this.searchFinishBuyMonth()
          break
        }
      }
    },
    // 这里来处理各个视图的检索
    searchData () {
      // 如果时间戳没有更新
      if (this.currentTime === 0 && this.currentTab !== 'list') {
        return
      }
      const list = [
        {
          icon: 'iconjijia_dangqian',
          title: '全部',
          type: '0',
          num: 0,
          data: []
        },
        {
          icon: 'icondaichuli',
          title: '待处理',
          type: '1',
          num: 0,
          data: []
        },
        {
          icon: 'iconfaqi',
          title: '我发起的',
          type: '2',
          num: 0,
          data: []
        },
        {
          icon: 'iconchaosong',
          title: '抄送给我',
          type: '3',
          num: 0,
          data: []
        },
        {
          icon: 'iconyiwancheng',
          title: '已处理',
          type: '-1',
          data: [],
          num: 0
        },
      ]
      this.$store.commit('setIndexMenuList', list)
      switch (this.currentTab) {
        /** 列表 */
        case 'list': {
          this.searchDataBuyList()
          break
        }
        /** 日 */
        case 'date': {
          this.searchDataBuyDay()
          break
        }
        /** 周 */
        case 'week': {
          this.searchDataBuyWeek()
          break
        }
        /** 月 */
        case 'month': {
          this.searchDataBuyMonth()
          break
        }
      }
    },
    // 月检索
    searchDataBuyMonth () {
      const date = new Date()
      date.setTime(this.currentTime)
      const start_time = format(startOfMonth(date), 'yyyy-MM-dd') + ' 00:00:00'
      const end_time = format(endOfMonth(date), 'yyyy-MM-dd') + ' 23:59:59'
      const params = { start_time, end_time }

      const paramsStore = this.$store.getters.getSearchParams
      for (const key in paramsStore) {
        if (paramsStore[key] && !['start_time', 'end_time'].includes(key)) {
          params[key] = paramsStore[key]
        }
      }

      this.seacrhMethod(params)
    },
    // 周检索
    searchDataBuyWeek () {
      let date = new Date()
      date.setTime(this.currentTime)
      date = addDays(date, (0 - getDay(date) + 1))
      const start_time = format(date, 'yyyy-MM-dd') + ' 00:00:00'
      const end_time = format(addDays(date, 6), 'yyyy-MM-dd') + ' 23:59:59'
      const params = { start_time, end_time }

      const paramsStore = this.$store.getters.getSearchParams
      for (const key in paramsStore) {
        if (paramsStore[key] && !['start_time', 'end_time'].includes(key)) {
          params[key] = paramsStore[key]
        }
      }
      this.seacrhMethod(params)
    },
    // 日检索
    searchDataBuyDay () {
      const date = new Date()
      date.setTime(this.currentTime)
      const start_time = format(date, 'yyyy-MM-dd') + ' 00:00:00'
      const end_time = format(date, 'yyyy-MM-dd') + ' 23:59:59'
      const params = { start_time, end_time }

      const paramsStore = this.$store.getters.getSearchParams
      for (const key in paramsStore) {
        if (paramsStore[key] && !['start_time', 'end_time'].includes(key)) {
          params[key] = paramsStore[key]
        }
      }
      this.seacrhMethod(params)
    },
    // 列表检索
    searchDataBuyList () {
      // 排序
      const reqParams = {}
      const sortFiled = this.$store.getters.getSortFiled
      if (sortFiled) {
        reqParams.order_field = sortFiled
      }
      // 筛选
      const params = this.$store.getters.getSearchParams
      for (const key in params) {
        if (params[key]) {
          reqParams[key] = params[key]
        }
      }
      this.seacrhMethod(reqParams)
    },
    seacrhMethod (params) {
      this.$store.commit('setSearchLoading', true)
      getNewHomeList(params).then(res => {
        // console.log(res.data)
        if (res.data.code === 200) {
          const data = res.data.data
          // 进行筛选
          this.dealData(data)
        }
      }).catch(() => { }).finally(() => {
        this.$store.commit('setSearchLoading', false)
      })
    },
    dealData (data) {
      const list = [
        {
          icon: 'iconjijia_dangqian',
          title: '全部',
          type: '0',
          num: 0,
          data: []
        },
        {
          icon: 'icondaichuli',
          title: '待处理',
          type: '1',
          num: 0,
          data: []
        },
        {
          icon: 'iconfaqi',
          title: '我发起的',
          type: '2',
          num: 0,
          data: [],
        },
        {
          icon: 'iconchaosong',
          title: '抄送给我',
          type: '3',
          num: 0,
          data: []
        },
        {
          icon: 'iconyiwancheng',
          title: '已处理',
          type: '-1',
          data: [],
          num: 0
        },
      ]
      // 如果不是数组停止
      if (!Array.isArray(data)) {
        return
      }
      list[0].data = data
      data.map(el => {
        switch (el.msg_type) {
          case 1: {
            list[1].data.push(el)
            break
          }
          case 2: {
            list[2].data.push(el)
            break
          }
          case 3: {
            list[3].data.push(el)
            break
          }
        }
      })
      list.map(el => {
        el.num = el.data.length // 记录条数
        el.allDayList = el.data.filter(el => el.allDay === 1) // 返回全天列表
      })
      // 存入vuex 方便各方组件跨层级调用
      this.$store.commit('setIndexMenuList', list)
      this.$store.commit('setActiveItem', list[0])
      this.$store.commit('setActiveItemIndex', 0)
    },
    // 月已完成检索
    searchFinishBuyMonth () {
      const date = new Date()
      date.setTime(this.currentTime)
      const start_time = format(startOfMonth(date), 'yyyy-MM-dd') + ' 00:00:00'
      const end_time = format(endOfMonth(date), 'yyyy-MM-dd') + ' 23:59:59'
      const params = { start_time, end_time, size: 1000, page: 1 }
      this.seacrhFinishMethod(params)
    },
    // 周已完成检索
    searchFinishBuyWeek () {
      let date = new Date()
      date.setTime(this.currentTime)
      date = addDays(date, (0 - getDay(date) + 1))
      const start_time = format(date, 'yyyy-MM-dd') + ' 00:00:00'
      const end_time = format(addDays(date, 6), 'yyyy-MM-dd') + ' 23:59:59'
      const params = { start_time, end_time, size: 1000, page: 1 }
      this.seacrhFinishMethod(params)
    },
    // 日已完成检索
    searchFinishBuyDay () {
      const date = new Date()
      date.setTime(this.currentTime)
      const start_time = format(date, 'yyyy-MM-dd') + ' 00:00:00'
      const end_time = format(date, 'yyyy-MM-dd') + ' 23:59:59'
      const params = { start_time, end_time, size: 1000, page: 1 }
      this.seacrhFinishMethod(params)
    },
    // 列表已完成检索
    searchFinishBuyList () {
      // 筛选
      const params = this.$store.getters.getSearchParams
      const reqParams = {
        ...this.page,
      }
      if (params.start_time) {
        reqParams.start_time = params.start_time
      }
      if (params.end_time) {
        reqParams.end_time = params.end_time
      }
      this.seacrhFinishMethod(reqParams)
    },
    seacrhFinishMethod (params) {
      this.$store.commit('setSearchLoading', true)
      getFinishedList(params).then(res => {
        if (res.data.code === 200) {
          const data = res.data.data
          // 进行筛选
          this.dealFinishData(data)
        }
      }).catch(() => { }).finally(() => {
        this.$store.commit('setSearchLoading', false)
      })
    },
    // 处理已完成数据
    dealFinishData (data) {
      const res = {
        icon: 'iconyiwancheng',
        title: '已处理',
        type: '-1',
        data: data.data,
        num: data.total,
        total: data.total
      }
      this.$store.commit('setActiveItem', res)
    }
  }
};
</script>
<style lang="less">
.home-main {
  .main-box {
    .content {
      // loading效果
      .loding-div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @keyframes reateIcon {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        .reate-icon {
          animation-name: reateIcon;
          animation-duration: 1s;
          animation-iteration-count: infinite;
        }
      }
      // 滚动条样式
      .scrollbar-self {
        &::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
          height: 1px;
        }
        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 5px;
          box-shadow: inset 0 0 5px rgb(125, 125, 125);
          background: #535353;
        }
        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          box-shadow: inset 0 0 5px rgb(125, 125, 125);
          border-radius: 5px;
          background: #ededed;
        }
      }
      .all-day {
        .list {
          .scrollbar-self();
        }
        .all-content {
          .scrollbar-self();
        }
      }
      .schedule-main {
        .scrollbar-self();
      }
      .content {
        .scrollbar-self();
      }
      .month-main {
        .scrollbar-self();
      }
    }
  }
}
</style>
<style lang="less" scoped>
.main-box {
  height: 100%;
  flex: 1;
  width: 60%;
  // border-right: 1px solid #eaebed;
  background: #fff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    padding: 3px 0;
    overflow: hidden;
  }
}
</style>