<template>
    <div>
        <div v-if="conOpen" class="footer-main">
            <div class="task-details">
                <div class="task-details-header">
                    <span class="header-tap-name tap-active" style="margin-right: 10px">反馈</span>
                </div>
                <div class="task-details-main">
                    <div class="details-view">
                        <el-form
                            ref="editorForm"
                            :model="formData"
                            :rules="rules"
                            :show-message="false"
                        >
                            <div class="details-form-item">
                                <div class="details-form-inneritem">
                                    <p>
                                        <el-form-item prop="status">
                                            <!-- type为2时为反馈 -->
                                            <RadioGroup v-if="infoData.type === 2" v-model="formData.status">
                                                <Radio :label="2">
                                                    <span>未完成</span>
                                                </Radio>
                                                <Radio :label="3">
                                                    <span>已完成</span>
                                                </Radio>
                                            </RadioGroup>
                                            <RadioGroup v-if="infoData.type === 3" v-model="formData.status">
                                                <template v-for="(item, index) in infoData.operate">
                                                    <Radio v-show="item.status !== 2" :key="index" :label="item.status">
                                                        <span>{{ item.name }}</span>
                                                    </Radio>
                                                </template>
                                            </RadioGroup>
                                        </el-form-item>
                                    </p>
                                    <p class="item-content">
                                        <el-form-item prop="content" :required="formData.status === 2 && infoData.type === 2">
                                            <Input v-model="formData.content" type="textarea" :rows="3" placeholder="请输入" />
                                        </el-form-item>
                                    </p>
                                </div>
                            </div>
                            <div class="details-form-item">
                                <div class="details-form-inneritem">
                                    <p class="item-header">附件</p>
                                    <div  class="item-content">
                                        <DragFiles :showFooter="false" :showHeader="false" :files="formData.file" @extra-files="(res) => { formData.file = res }"></DragFiles>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="details-form-item" v-if="infoData.type === 3">
                                <div class="details-form-inneritem">
                                    <p class="item-header">审批人<span class="require-label">*</span></p>
                                    <p class="item-content">
                                        <FormItem prop="approver">
                                            <SelectUser :show-list="formData.approver" isCheckType="selectCheckbox" @on-choose="(res) => { formData.approver = res.map(el => { return {user_id: el.user_id, role_id: el.role_id}})}"></SelectUser>
                                        </FormItem>
                                    </p>
                                </div>
                                <div class="details-form-inneritem">
                                    <p class="item-header">抄送人</p>
                                    <p class="item-content">
                                        <SelectUser :show-list="formData.ccer" isCheckType="selectCheckbox" @on-choose="(res) => { formData.ccer = res.map(el => { return {user_id: el.user_id, role_id: el.role_id}})}"></SelectUser>
                                    </p>
                                </div>
                            </div> -->
                        </el-form>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="footer-btn">
            <i class="iconfont" size="24" @click="conOpen = !conOpen" :class="conOpen ? 'iconjijia_xiangxia' : 'iconjijia_xiangshang'"></i>
            <Button type="primary" :loading="loading" @click="handleClick"> {{ conOpen ? '确 认' : '反 馈' }}</Button>
        </div>
    </div>
    
</template>

<script>
//import { format, addDays, addHours } from "date-fns"
//import {Popover,Form,FormItem,Select,Option,DatePicker,Button} from 'element-ui'
import { operateWorlPlan as operateTask } from '@/apis/objectivesRespon'
import { taskFlowOperat } from '@/apis/home'
//import SelectUser from '@/components/selectUser/index'
import DragFiles from '../FileUpload.vue'
export default {
    props: {
        infoData: {
            type: Object,
            require: true
        }
    },
    components: { 
        // SelectUser,
     DragFiles,
    //   'el-popover':Popover,
    // 'el-form':Form,
    // 'el-form-item':FormItem,
    // 'el-select':Select,
    // 'el-option':Option,
    // 'el-date-picker':DatePicker,
    // 'el-button':Button, 
    },
    data() {
        return {
            active: 'details',
            formData: {
                status: '',
                content: '',
                approver: [],
                ccer: [],
                file: []
            },
            rules: {
                approver: [
                    { required: true, message: '审批人不能为空', trigger: 'change' }
                ],
            },
            conOpen: false,
            loading: false
        }
    },
    methods: {
        // 捕获点击事件
        handleClick() {
            if (!this.conOpen) {
                this.conOpen = true
            } else {
                if (this.infoData.type === 2) {
                    // 为2时单纯反馈
                    this.feedback()
                } else if (this.infoData.type === 3) {
                    this.taskFlowOperat()
                }
            }
        },
        taskFlowOperat() {
            if(this.formData.status) {
                const data = {
                    modult_name: 'assignment',
                    id: this.infoData.id,
                    status: this.formData.status,
                    note: this.formData.content,
                    img: this.formData.file
                }
                this.loading = true
                taskFlowOperat(data).then(res => {
                    if(res.data.code === 200) {
                        this.conOpen = false
                        this.$emit('refresh')
                    }
                }).catch(() => {}).finally(() => {
                    this.loading = false
                })
            } else {
                this.$Message.error('请确保状态已填写！')
            }
        },
        feedback() {
            if(this.formData.status) {
                if(this.formData.status === 2) {
                    if (this.formData.content) {
                        const id = this.infoData.id
                        const data = {
                            module_name: this.infoData.module_name,
                            status: this.formData.status,
                            content: this.formData.content,
                            file: this.formData.file,
                            approver: [
                                {
                                    role_id: this.infoData.role_id,
                                    user_id: this.infoData.user_id
                                }
                            ]
                        }
                        if(this.formData.status === -1) {
                            delete data.approver
                        }
                        this.loading = true
                        operateTask(id, data).then(res => {
                            if(res.data.code === 200) {
                                this.conOpen = false
                                this.$emit('refresh')
                                this.$store.commit('setRefesh')
                            }
                        }).catch(() => {}).finally(() => {
                            this.loading = false
                        })
                    } else {
                        this.$Message.error('当状态为为完成时请输入为完成原因！')
                    }
                }
            } else {
                this.$Message.error('请确保状态已填写！')
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .footer-main{
        .task-details{
            height: 100%;
            width: 100%;
            .task-details-header{
                background: #fff;
                padding: 8px;
                display: flex;
                border-bottom: 1px solid #eee;
                justify-content: space-between;
                .header-tap-name{
                    padding: 4px;
                    font-size: 14px;
                    font-family: PingFangSC, PingFangSC-Medium;
                    font-weight: 500;
                    text-align: left;
                    color: rgba(138,143,153,1);
                    line-height: 22px;
                }
                .tap-active{
                    color: rgba(37,40,46,1);
                    border-bottom: 3px solid rgba(0,129,153,1);
                }
                .icon{
                    font-size: 16px;
                    line-height: 22px;
                }
            }
            .task-details-main{
                height: calc(~"100% - 40px");
                .details-view{
                    height: 100%;
                    .details-form-item{
                        background: #fff;
                        margin-bottom: 12px;
                        .details-form-inneritem{
                            border-bottom: 1px solid #eee;
                            padding: 12px 16px;
                            .require-label{
                                color: rgba(204,68,41,1);
                            }
                            .item-header{
                                padding: 2px 0;
                                font-size: 12px;
                                font-family: PingFangSC, PingFangSC-Regular;
                                font-weight: 400;
                                text-align: left;
                                line-height: 20px;
                                color: rgba(32,33,38,1);
                            }
                            .item-content{
                                font-size: 14px;
                                font-family: PingFangSC, PingFangSC-Regular;
                                font-weight: 400;
                                text-align: left;
                                color: #202126;
                                line-height: 22px;
                                .task-priority{
                                    color: rgba(221,44,47,1);
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                    .text-p{
                        width: 100%;
                    }
                }
            }
        }
    }
    .footer-btn{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
    }
    
</style>

<style lang="less">
    .task-details{
        .ivu-form-item{
            margin: 0;
            .ivu-date-picker{
                width: 100%;
            }
        }
    }
</style>