<template>
    <div>
        <!-- 弹窗新增任务 -->
        <header>新增任务</header>
        <Form
        >

        </Form>
    </div>
</template>
<script>
export default {
    props: {
        infoData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {}
    }
}
</script>