var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"monthView",staticClass:"month-view"},[_c('header',{staticClass:"weekday"},_vm._l((_vm.weekData),function(weekDay,index){return _c('p',{key:index,class:{ 'weekend': index === 5 || index === 6 }},[_vm._v(_vm._s(weekDay))])}),0),_c('article',{ref:"monthMain",staticClass:"month-main"},_vm._l((_vm.dateList),function(item,index){return _c('section',{key:item.date,ref:'dateItem' + index,refInFor:true,staticClass:"date-item"},[_c('div',{staticClass:"date-label"},[_c('label',{class:{
          'special': item.disabled,
          'is-today': item.isToday
        }},[_vm._v(_vm._s(item.label))])]),(item.todoList && item.todoList.length)?[_c('ul',{staticClass:"todo-list"},_vm._l((item.todoList),function(todo,todoIndex){return ((item.todoList.length > 5 && todoIndex < 4) || item.todoList.length <= 5)?_c('li',{key:todoIndex,ref:'todo' + index + '-' + todoIndex,refInFor:true,staticClass:"todo ellipsis",class:{
              'is-full-complate': todo.allDay && ![2, 5].includes(todo.status), //todo.status === 1, // 全天完成
              'is-full-unfinish': todo.allDay && [2, 5].includes(todo.status), //todo.status !== 1, // 全天未完成
              'is-foucus': _vm.focusId === todo.id, // 当前聚焦
              'full-day': todo.allDay, // 全天
              'is-span': todo.isSpan, // 跨天日常
              'is-start-full': todo.isSpanStart, // 全天 跨天 开始那天
              'is-end-full': todo.isSpanEnd, // 全天 跨天 结束那天
              'not-full-day': !todo.allDay, // 非全天
              'target-create': _vm.quietCreate && _vm.quietCreate.date === item.date && _vm.quietCreate.spaceTodoIndex === todoIndex // 创建
            },on:{"click":function($event){return _vm.handleItemClick(todo)},"mouseover":function($event){return _vm.onMouseover(todo)},"mouseout":_vm.onMouseout}},[(!todo.allDay && !todo.isSpace && todo.msg_type !== 3)?_c('label',{staticClass:"point",class:{
                'unfinish': [2, 5].includes(todo.status), //todo.status === 2
              }}):_vm._e(),((!todo.allDay
                || !todo.isSpan
                || (todo.isSpanStart || todo.isMonday || (item.day === 1 && index === 0)))
                && (
                  !todo.isSpace
                  ||
                  (_vm.quietCreate && _vm.quietCreate.date === item.date && _vm.quietCreate.spaceTodoIndex === todoIndex)
                ))?_c('p',{staticClass:"title ellipsis",class:{
                'not-un-finished': !todo.allDay && [2, 5].includes(todo.status), //todo.status === 2
              }},[_vm._v(" "+_vm._s(todo.title || '无主题')+" ")]):_vm._e()]):_vm._e()}),0),(item.todoList.length > 5)?_c('p',{staticClass:"overflow ellipsis",on:{"click":function($event){return _vm.onShowHide(index)}}},[_vm._v("还有"+_vm._s(item.todoList.length - 4)+"项")]):_vm._e(),(item.todoList.length > 5 && _vm.showAll && _vm.showMoreIndex === index)?_c('section',{staticClass:"all-todo"},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(item.label))]),_c('ul',{staticClass:"list"},_vm._l((item.todoList),function(todo,todoIndex){return _c('li',{key:todoIndex,ref:'todo' + index + '-full-' + todoIndex,refInFor:true,staticClass:"todo ellipsis",class:{
                'is-full-complate': todo.allDay && ![2, 5].includes(todo.status), //todo.status === 1, // 全天完成
                'is-full-unfinish': todo.allDay && [2, 5].includes(todo.status), //todo.status !== 1, // 全天未完成
                'is-foucus': _vm.focusId === todo.id, // 当前聚焦
                'full-day': todo.allDay, // 全天
                'is-span': todo.isSpan, // 跨天日常
                'is-start-full': todo.isSpanStart, // 全天 跨天 开始那天
                'is-end-full': todo.isSpanEnd, // 全天 跨天 结束那天
                'not-full-day': !todo.allDay, // 非全天
                'target-create': _vm.quietCreate && _vm.quietCreate.date === item.date && _vm.quietCreate.spaceTodoIndex === todoIndex // 创建
              },on:{"click":function($event){return _vm.handleItemClick(todo)}}},[(!todo.isSpace && todo.msg_type !== 3)?_c('label',{staticClass:"point",class:{
                  'unfinish': [2, 5].includes(todo.status), //todo.status === 2
                }}):_vm._e(),_c('p',{staticClass:"child-title ellipsis"},[_vm._v(" "+_vm._s(todo.title || '无主题')+" ")])])}),0)]):_vm._e()]:_vm._e()],2)}),0),(_vm.showModel)?_c('Popup',{attrs:{"positon":_vm.popupPosition,"infoData":_vm.detailsObj}}):_vm._e(),(_vm.showModel || _vm.showAll)?_c('div',{staticClass:"mask-box",on:{"click":_vm.onMask}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }