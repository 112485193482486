<template>
    <div class="details-view">
        <el-scroll>
            <div class="details-form-item">
                <div class="details-form-inneritem">
                    <p class="item-header active">任务标题</p>
                    <p class="item-content">{{ infoData.name ? infoData.name : '-'}}</p>
                </div>
                <div class="details-form-inneritem">
                    <p class="item-header active">任务描述</p>
                    <p class="item-content">{{ '-'}}</p>
                </div>
            </div>
            <div class="details-form-item">
                <div class="details-form-inneritem">
                    <p class="item-header active">执行人</p>
                    <p class="item-content">{{ formatPerson(infoData.receivers) }}</p>
                </div>
                <div class="details-form-inneritem">
                    <p class="item-header active">抄送人</p>
                    <p class="item-content">{{ formatPerson(infoData.supervisors) }}</p>
                </div>
                <div class="details-form-inneritem">
                    <p class="item-header active">优先级</p>
                    <p class="item-content"><span class="task-priority">{{'!'.repeat(infoData.priority)}}</span> &nbsp;{{ priorityFormat(infoData.priority) }}</p>
                </div>
            </div>
            <div class="details-form-item">
                <div class="details-form-inneritem">
                    <p class="item-header active">开始时间</p>
                    <p class="item-content">{{ infoData.start_time ? infoData.start_time.substring(0, infoData.start_time.length - 3).replaceAll('-', '/') : '-' }}</p>
                </div>
                <div class="details-form-inneritem">
                    <p class="item-header active">结束时间</p>
                    <p class="item-content">{{ infoData.deadline ? infoData.deadline.substring(0, infoData.deadline.length - 3).replaceAll('-', '/') : '-' }}</p>
                </div>
                <div class="details-form-inneritem">
                    <p class="item-header active">重复</p>
                    <p class="item-content">暂无</p>
                </div>
            </div>
            <div class="details-form-item">
                <div class="details-form-inneritem">
                    <p class="item-header active">附件</p>
                    <p class="item-content">
                        <DragFiles disabled :showFooter="false" :showNone="true" :showHeader="false" :files="Array.isArray(infoData.file) ? infoData.file : []"/>
                    </p>
                </div>
            </div>
            <div class="details-form-item">
                <div class="details-form-inneritem">
                    <p class="item-header active">子任务</p>
                    <div class="item-content">
                        <p v-if="!Array.isArray(infoData.children) || infoData.children.length === 0">
                            无
                        </p>
                        <div v-else>
                            <p v-for="(item, index) in infoData.children" :key="index"> {{item.name}} </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="details-form-item">
                <div class="details-form-inneritem">
                    <p class="item-header active">父任务关联</p>
                    <div class="item-content">
                        <p v-if="infoData.parent_id === 0">
                            无
                        </p>
                        <div v-else>
                           <!--暂时没有内容 -->
                           <p>
                               <i class="iconfont iconfujiguanlian"></i>
                           </p>
                        </div>
                    </div>
                </div>
            </div>
        </el-scroll>
    </div>
</template>

<script>
import DragFiles from '../FileUpload.vue'
import { Scrollbar } from 'element-ui'
export default {
    components: {
        DragFiles,
         'el-scroll':Scrollbar
    },
    props: {
        infoData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            archi_id: localStorage.getItem('archi').split('/')[2]
        }
    },
    methods: {
        formatPerson(arr) {
            if(!Array.isArray(arr) || arr.length === 0) {
                return '无'
            }
            const persons = arr.map(el => {
                return `【${el.user_name}】`
            })
            if (persons.length > 3) {
                return `${persons.slice(0, 3).join('')}...等${persons.length}人`
            } else{
                return `${persons.join('')}`
            }
        },
        priorityFormat(status) {
            if(!status) {
                return '无'
            }
            const temp = {
                1: '较低',
                2: '普通',
                3: '紧急',
                4: '紧急重要'
            }
            return temp[status]
        }

    }
}
</script>

<style lang="less" scoped>
    .details-view{
        height: 100%;
        .details-form-item{
            background: #fff;
            margin-bottom: 12px;
            .details-form-inneritem{
                border-bottom: 1px solid #eee;
                padding: 12px 16px;
                .item-header{
                    font-size: 12px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    text-align: left;
                    color: #445e96;
                    line-height: 20px;
                }
                .item-content{
                    font-size: 14px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    text-align: left;
                    color: #202126;
                    line-height: 22px;
                    .task-priority{
                        color: rgba(221,44,47,1);
                        font-size: 14px;
                    }
                }
            }
        }
        .text-p{
            width: 100%;
        }
    }
</style>