// 这里存放模块相关的操作
import { getModulNames } from '@/apis/home'

const modulNames = new Map()

// 获取模块名请求
export const getModulNameFun = () => {
  if (modulNames.size == 0) {
    getModulNamesFun()
  }
}

async function getModulNamesFun() {
  await getModulNames().then((res) => {
    if (res.data.code === 200) {
      const data = res.data.data
      data.map((el) => {
        console.log(el)
        modulNames.set(el.module_name, {
          typeName: el.name,
          icon: getModulIcon(el.module_name).icon,
        })
      })
    }
  })
}

// 获取模块名最新
export const getModulName = (modulName, objName) => {
  return modulNames.has(modulName, objName)
    ? modulNames.get(modulName, objName)
    : getModulIcon(modulName, objName)
}
// 有时候存在不属于模块的抄送和通知，不删除是为了完整性
const getModulIcon = (modulName, objName) => {
  if (modulName.indexOf('evaluate_form') !== -1) {
    return { typeName: '履约评价', icon: 'iconlvyuepingjia' }
  }
  if (modulName.startsWith('seal')) {
    return { typeName: '印章申请', icon: 'iconyongzhangshenqing' }
  }
  switch (modulName) {
    case 'mat_supplier_apply': {
      return { typeName: '入库申请', icon: 'iconruku' }
    }
    case 'evaluate_form': {
      return { typeName: '履约评价', icon: 'iconlvyuepingjia' }
    }
    case 'assignment': {
      return { typeName: '任务节点', icon: 'iconjihua2' }
    }
    case 'material_scrap': {
      return { typeName: '废料', icon: 'iconwuziguanli' }
    }
    case 'CISDIList_bidding': {
      return { typeName: '流程审批', icon: 'iconliucheng' }
    }
    case 'diagram_task': {
      return { typeName: '拓扑图任务', icon: 'icontuoputu' }
    }
    case 'fixed': {
      return { typeName: '定点巡查', icon: 'icondingdianxuncha' }
    }
    case 'user_ask_leave': {
      return { typeName: '请假', icon: 'iconqingjia' }
    }
    case 'hr_business_trip': {
      return { typeName: '出差申请', icon: 'iconchucha' }
    }
    case 'materialApply': {
      return { typeName: '物资管理', icon: 'iconwuziguanli' }
    }
    case 'material_apply': {
      return { typeName: '物资管理', icon: 'iconwuziguanli' }
    }
    case 'monthlyReport': {
      return { typeName: '月报', icon: 'iconyuebao' }
    }
    case 'qualityMonthlyReport': {
      return { typeName: '月报', icon: 'iconyuebao' }
    }
    case 'plan': {
      return { typeName: '文件审批', icon: 'iconwenjianshenpi' }
    }
    case 'quality': {
      return { typeName: '质量问题', icon: 'iconzhiliangguanli1' }
    }
    case 'security': {
      return { typeName: '安全问题', icon: 'iconanquanrizhi1' }
    }
    case 'returnLibrary': {
      return { typeName: '退库申请', icon: 'icontuiku' }
    }
    case 'return_library': {
      return { typeName: '退库申请', icon: 'icontuiku' }
    }
    case 'receipt': {
      return { typeName: '公文', icon: 'icongongwen1' }
    }
    case 'notice': {
      return { typeName: '公文', icon: 'icongongwen1' }
    }
    case 'smoke': {
      return { typeName: '烟感', icon: 'iconyangan' }
    }
    case 'meeting2': {
      return { typeName: '会议', icon: 'iconhuiyiguanli' }
    }
    case 'meeting2_host': {
      return { typeName: '会议', icon: 'iconhuiyiguanli' }
    }
    case 'meeting2_notice': {
      return { typeName: '会议', icon: 'iconhuiyiguanli' }
    }
    case 'sealApply': {
      return { typeName: '用章', icon: 'iconyongzhangshenqing' }
    }
    case 'task1': {
      return { typeName: '问题汇报', icon: 'iconwentihuibao' }
    }
    case 'task2': {
      return { typeName: '工作联系单', icon: 'iconrenwu' }
    }
    case 'dispatch': {
      return { typeName: '调度令', icon: 'icontiaoduling1' }
    }
    case 'daily': {
      return { typeName: '日报', icon: 'iconribao' }
    }
    case 'td': {
      return { typeName: '技术交底', icon: 'iconjiaodi1' }
    }
    case 'technical_disclosure': {
      return { typeName: '技术交底', icon: 'iconjiaodi1' }
    }
    case 'gantt_mission': {
      return { typeName: '进度计划', icon: 'iconjihuarenwu' }
    }
    case 'acceptance': {
      return { typeName: '工序验收', icon: 'iconyanshou' }
    }
    case 'pre_shift_meetings': {
      return { typeName: '班前会', icon: 'meetingBefore' }
    }
    case 'material_scheduling': {
      return { typeName: '排产单', icon: 'iconpaichandan' }
    }
    case 'foundation_pit_monitors': {
      return { typeName: '基坑监测', icon: 'iconjikeng' }
    }
    case 'week_report': {
      return { typeName: '安全周报', icon: 'iconanquanrizhi1' }
    }
    case 'space_approve': {
      return { typeName: '审批网盘', icon: 'iconshenpiwangpan' }
    }
    case 'demolition': {
      return { typeName: '拆迁管理', icon: 'iconchaiqian' }
    }
    case 'equipment': {
      return { typeName: '设备进场', icon: 'iconjinchang' }
    }
    case 'design_drawing_check': {
      return { typeName: '图审管理', icon: 'icontushen' }
    }
    case 'design_service': {
      return { typeName: '施工配合管理', icon: 'iconshigongpeihe' }
    }
    case 'equipment_inspect': {
      return { typeName: '设备报验', icon: 'iconshebeibaoyan' }
    }
    case 'highlight': {
      return { typeName: '评优创先', icon: 'iconpingyouchuangxian' }
    }
    case 'sa_inspection_task': {
      return { typeName: '安全巡检任务', icon: 'iconxunjian' }
    }
    case 'qa_inspection_task': {
      return { typeName: '质量巡检任务', icon: 'iconxunjian' }
    }
    case 'equipment_patrol_log': {
      return { typeName: '设备巡检', icon: 'iconshebeiguanli' }
    }
    // case "assignment": {
    //   return { typeName: "任务", icon: "iconjihuarenwu" };
    // }
    case 'supervisors': {
      return { typeName: '监理日志', icon: 'iconjianlirizhi1' }
    }
    case 'build_log': {
      return { typeName: '施工日志', icon: 'iconshigongrizhi1' }
    }
    case 'sa_log': {
      return { typeName: '安全日志', icon: 'iconjianlianquanrizhi' }
    }
    case 'equipment_maintenance': {
      return { typeName: '设备维保', icon: 'iconweibao' }
    }
    case 'hr_user_ask_leave': {
      return { typeName: '请假申请', icon: 'iconqingjia' }
    }
    // case "user_ask_leave": {
    //   return { typeName: "请假申请", icon: "iconqingjia" };
    // }
    case 'cancel_leave': {
      return { typeName: '销假申请', icon: 'iconqingjia' }
    }
    case 'returnBusinessTrip': {
      return { typeName: '返程申请', icon: 'iconqingjia' }
    }
    case 'supervisor_safety': {
      return { typeName: '监理安全日志', icon: 'iconjianlianquanrizhi' }
    }
    case 'car_apply': {
      return { typeName: '用车申请', icon: 'iconyongche' }
    }
    case 'monitor_daily': {
      return { typeName: '监测管理', icon: 'iconjiance1' }
    }
    case 'pwg_news': {
      return { typeName: '新闻宣传', icon: 'iconxinwen' }
    }
    case 'company_pwg_news': {
      return { typeName: '新闻宣传', icon: 'iconxinwen' }
    }
    case 'material_income_expenditure': {
      return { typeName: '收支存', icon: 'iconshouzhicun' }
    }
    case 'gantt_plans': {
      return { typeName: '进度计划', icon: 'iconjihuarenwu' }
    }
    case 'equipment_exit': {
      return { typeName: '设备退场', icon: 'icontuiku' }
    }
    case 'diagram': {
      return { typeName: '拓扑图预警', icon: 'icontuoputu' }
    }
    case 'design_profit': {
      return { typeName: '设计成果', icon: 'iconshejishencha' }
    }
    case 'tech_counsel': {
      return { typeName: '技术咨询', icon: 'iconjiaodi1' }
    }
    case 'prospect_profit': {
      return { typeName: '勘察成果', icon: 'iconkanchasheji' }
    }
    case 'draw_joint_check': {
      return { typeName: '图纸会审', icon: 'icontuzhi4' }
    }
    case 'design_confess': {
      return { typeName: '设计交底', icon: 'iconshejishencha' }
    }
    case 'material_deviation': {
      return { typeName: '材料调差', icon: 'iconwuziguanli' }
    }
    case 'safety_weather_alarm': {
      return { typeName: '天气预警', icon: 'iconfengxianguankong' }
    }
    case 'change_claim': {
      return { typeName: '变更索赔', icon: 'iconbiangengguanli1' }
    }
    case 'pay_mange': {
      return { typeName: '支付管理', icon: 'iconcaiwuguanli' }
    }
    case 'bim_counsel': {
      return { typeName: 'BIM咨询', icon: 'iconBIM' }
    }
    case 'profit_use': {
      return { typeName: '成果运用', icon: 'iconBIM' }
    }
    case 'design_change': {
      return { typeName: '设计变更', icon: 'iconbiangengguanli1' }
    }
    case 'invest_counsel': {
      return { typeName: '咨询成果', icon: 'icontouzichanchubi' }
    }
    case 'iot_environment': {
      return { typeName: '环境监测', icon: 'iconyangchen' }
    }
    case 'iot_detector': {
      return { typeName: '烟感', icon: 'iconyangan' }
    }
    case 'iot_crane': {
      return { typeName: '塔吊报警', icon: 'icontadiao1' }
    }
    case 'iot_foundation': {
      return { typeName: '基坑报警', icon: 'iconjikeng' }
    }
    case 'iot_high-formwork': {
      return { typeName: '高支模监测', icon: 'icongaozhimo' }
    }
    case 'iot_elevator': {
      return { typeName: '升降机监测', icon: 'iconshengjiangji' }
    }
    case 'official_document2': {
      return { typeName: '公文管理', icon: 'icongongwen1' }
    }
    case 'official_document2_send': {
      return { typeName: '发文管理', icon: 'icongongwen1' }
    }
    case 'capital_plan': {
      return { typeName: '资金计划', icon: 'iconyongzhangshenqing' }
    }
    case 'safety_assess': {
      return { typeName: '自评报告', icon: 'iconyongzhangshenqing' }
    }
    case 'contract_ci': {
      return { typeName: '建安合同', icon: 'iconwuzifenlei' }
    }
    case 'contract_ci_claimant': {
      return { typeName: '工程索赔', icon: 'iconwuzifenlei' }
    }
    case 'material_cost_owner': {
      return { typeName: '目标成本', icon: 'iconbimgis_shuxing' }
    }
    case 'material_cost_owner_change_cost': {
      return { typeName: '目标成本调整', icon: 'iconbimgis_shuxing' }
    }
    case 'material_cost_owner_change_turn': {
      return { typeName: '目标成本转结', icon: 'iconbimgis_shuxing' }
    }
    case 'material_cost_owner_change_budget': {
      return { typeName: '预备费调整', icon: 'iconwuziguanli' }
    }
    case 'work_plan': {
      return { typeName: '工作计划', icon: 'iconrenwu' }
    }
    case 'responsibility_log': {
      return { typeName: '岗位职责', icon: 'iconrenwu' }
    }
    case 'emergency_organization_apply': {
      return { typeName: '应急管理', icon: 'iconfengxianguankong' }
    }
    case 'emergency_team_apply': {
      return { typeName: '应急管理', icon: 'iconfengxianguankong' }
    }
    case 'emergency_material_apply': {
      return { typeName: '应急物资', icon: 'iconfengxianguankong' }
    }
    case 'design_review': {
      return { typeName: '设计审查', icon: 'iconshejishencha' }
    }
    case 'security_investment_expenditure': {
      return { typeName: '费用支出', icon: 'icontaizhang' }
    }
    case 'security_investment_plan': {
      return { typeName: '费用计划', icon: 'icontaizhang' }
    }
    case 'equipment_scrap_normal': {
      return { typeName: '设备报废', icon: 'iconshebeiguanli' }
    }
    case 'equipment_scrap_abnormal': {
      return { typeName: '设备报废', icon: 'iconshebeiguanli' }
    }
    // case "material_scrap": {
    //   return { typeName: "物资报废", icon: "iconshebeiguanli" };
    // }
    case 'Questionnaire': {
      return { typeName: '问卷调查', icon: 'iconpingyouchuangxian' }
    }
    case 'work_flow_section_approval': {
      return { typeName: '工作周报', icon: 'iconrizhi' }
    }
    case 'work_flow_all_approval': {
      return { typeName: '工作周报', icon: 'iconrizhi' }
    }
    case 'work_log': {
      return { typeName: '工作周报', icon: 'iconrizhi' }
    }
    case 'new_weekly_flow_manage': {
      return { typeName: '工作周报', icon: 'iconrizhi' }
    }
    case 'new_weekly_flow_section': {
      return { typeName: '工作周报', icon: 'iconrizhi' }
    }
    case 'new_weekly': {
      return { typeName: '工作周报', icon: 'iconrizhi' }
    }
    case 'energy_save': {
      return {
        typeName: '能源节约与生态环境保护',
        icon: 'iconfengxianguankong',
      }
    }
    case 'emergency_drill': {
      return { typeName: '应急演练', icon: 'iconfengxianguankong' }
    }
    case 'emergency_reserve_plan': {
      return { typeName: '应急预案', icon: 'iconfengxianguankong' }
    }
    case 'emergency_reserve_plan_assessment': {
      return { typeName: '应急评估', icon: 'iconfengxianguankong' }
    }
    case 'quality_plan': {
      return { typeName: '质量计划', icon: 'iconjihua2' }
    }
    case 'task_supervision': {
      return { typeName: '任务督办', icon: 'iconhetong' }
    }
    case 'mat_supplier_apply_1': {
      return { typeName: '物料提供商', icon: 'iconwuziguanli' }
    }
    case 'mat_supplier_apply_2': {
      return { typeName: '物料提供商', icon: 'iconwuziguanli' }
    }
    case 'cons_service_apply_1': {
      return { typeName: '分包商单位审核', icon: 'iconfenbaoshenhe' }
    }
    case 'cons_service_apply_2': {
      return { typeName: '分包商单位审核', icon: 'iconfenbaoshenhe' }
    }
    case 'contract_sub_settlement': {
      return { typeName: '分包合同结算', icon: 'iconbimgis_shuxing' }
    }
    case 'marketing_briefing': {
      return { typeName: '市场经营简报', icon: 'iconbimgis_shuxing' }
    }
    case 'meeting2_remind': {
      return { typeName: '会议任务', icon: 'iconhuiyiguanli' }
    }
    case 'change_manage': {
      return { typeName: '变更管理', icon: 'iconbiangengguanli1' }
    }
    case 'material_apply2': {
      return { typeName: '年计划', icon: 'iconjihua2' }
    }
    case 'official_document': {
      return { typeName: '公文', icon: 'icongongwen1' }
    }
    default: {
      if (modulName.indexOf('folder_check') > -1) {
        return { typeName: '文件审批（网盘）', icon: 'iconshenpiwangpan' }
      } else if (modulName.indexOf('report_config') > -1) {
        // let arr = modulName.split('_')
        // let newModule = arr[0]+'_'+arr[1]+'_'+arr[2]
        // let moduleArr = this.module.filter(item=>item.module === newModule);
        let moduleName = '日周月报'
        // if(moduleArr[0]){
        //     moduleName = ((moduleArr[0].module_name).split('_'))[0]
        //     firstName = moduleArr[0].module_name.substring(0,1)
        // }
        return { typeName: moduleName, icon: 'iconzidingyibiaodan' }
      } else {
        let moduleName
        if (!objName) {
          moduleName = '自定义表单模块'
        } else {
          moduleName = objName
        }
        return { typeName: moduleName, icon: 'iconzidingyibiaodan' }
      }
    }
  }
}

// 跳转
export const toTurn = (that, val, item) => {
  // 预先处理
  item.data_id = item.msg_id
  /** */
  if (val.indexOf('evaluate_form') !== -1) {
    let isCSorYRC = false
    if (item.msg_type === 3 || [2, 5].includes(item.status)) {
      isCSorYRC = true
    }
    return that.$router.push({
      name: 'subEvaluate',
      params: {
        id: item.remind ? item.data_id : item.id,
        mark: item.module_name,
        isRemind: item.remind,
        isCSorYRC: isCSorYRC,
      },
    })
  }
  if (window.location.href.includes('bimConsole')) {
    //赛迪首页
  } else {
    //网盘文件审批
    if (val.indexOf('folder_check') > -1) val = 'folder_check'
    //日周月报
    if (val.indexOf('report_config') > -1) {
      if (item.title.indexOf('审核') > -1 && item.msg_type != 3) {
        item.module_name = item.module_name + '_' + item.id
        val = item.module_name
      }
      let arr = val.split('_')
      let addFlag = 1
      let params = {
        id: arr[2],
        name: '日周月报',
      }
      if (arr.length == 3) {
        //跳转新增
        addFlag = 1
      } else {
        //跳转详情
        addFlag = 2
        params.form_data_id = arr[3]
        params.time = item.created_at
      }
      params.addFlag = addFlag
      that.$router.push({
        name: 'dayweekmonth',
        params,
      })
      return
    }
    // 印章
    if (val.startsWith('seal_')) {
      return that.$router.push({
        name: 'sealApplication',
        params: { id: item.id },
      })
    }
    switch (val) {
      case 'mat_supplier_apply_1':
        that.$router.push({
          name: 'supplierAudit',
          params: { id: item.id },
        })
        break
      case 'sa_security':
        that.$router.push({
          name: 'securityPolling',
          params: { id: item.id },
        })
        break
      case 'seal':
        that.$router.push({
          name: 'sealApplication',
          params: { id: item.id },
        })
        break
      case 'folder_check':
        that.$router.push({
          name: 'listApprove',
          query: { id: item.id, module: item.module_name },
        })
        break
      case 'contract_ci': //建安合同
        that.$router.push({
          name: 'costManageContract',
          query: {
            id: item.id,
          },
        })
        break
      // 天气预警
      case 'safety_weather_alarm':
        that.$router.push({
          name: 'weatherWarning',
          query: {
            warnId: item.id,
          },
        })
        break
      case 'iot_environment':
        that.$router.push({
          name: 'environment',
        })
        break
      case 'iot_detector':
        that.$router.push({
          name: 'smoke',
        })
        break
      case 'iot_crane':
        that.$router.push({
          name: 'towerCrane',
        })
        break
      case 'iot_foundation':
        that.$router.push({
          name: 'foundationPit',
        })
        break
      case 'iot_high-formwork':
        that.$router.push({
          name: 'highModulu',
        })
        break
      case 'iot_elevator':
        that.$router.push({
          name: 'elevator',
        })
        break
      // unable  没有naem 字段
      case 'quality':
        that.$router.push({
          name: 'qualityPolling1',
          query: {
            archiId: item.archi_id,
            name: item.name,
            id: item.id,
            type: 3,
          },
        })
        break
      case 'security':
        that.$router.push({
          name: 'securityPolling',
          params: { id: item.id },
        })
        break
      case 'sa_inspection_task':
        that.$router.push({
          name: 'securityPollingTask',
          params: { id: item.id },
        })
        break
      case 'qa_inspection_task':
        that.$router.push({
          name: 'qualityPollingTask',
          params: { id: item.id },
        })
        break
      case 'plan':
        that.$router.push({ name: 'programme', params: { id: item.id } })
        break
      case 'monthlyReport':
        that.$router.push({
          name: 'monthlyMagazineList',
          params: { id: item.id },
        })
        break
      case 'qualityMonthlyReport':
        that.$router.push({
          name: 'monthlyMagazineList',
          params: { id: item.id },
        })
        break
      case 'materialApply':
        if (window.location.href.includes('crcc.cme')) {
          that.$router.push({ name: 'applyfor18', params: { id: item.id } })
        } else {
          that.$router.push({ name: 'applyfor', params: { id: item.id } })
        }
        break
      case 'material_apply':
        if (window.location.href.includes('crcc.cme')) {
          that.$router.push({ name: 'applyfor18', params: { id: item.id } })
        } else {
          that.$router.push({ name: 'applyfor', params: { id: item.id } })
        }
        break
      case 'returnLibrary':
        that.$router.push({ name: 'returnApply', params: { id: item.id } })
        break
      case 'return_library':
        that.$router.push({ name: 'returnApply', params: { id: item.id } })
        break
      case 'notice':
        that.$router.push({
          name: 'documentList',
          params: { id: item.id },
        })
        break
      case 'official_document':
        that.$router.push({
          name: 'documentList',
          params: { id: item.id },
        })
        break
      //  unable  item 可能参数不全
      case 'meeting2':
        if (that.listType === 'readRemind') {
          item.listType = 'readRemind'
        }
        that.$router.push({
          name: 'meetingManagement',
          params: { param: item, module: 'meeting2' },
        })
        break
      case 'meeting2_host':
        that.$router.push({
          name: 'meetingManagement',
          params: { param: item, module: 'meeting2_host' },
        })
        break
      case 'meeting2_notice':
        that.$router.push({
          name: 'meetingManagement',
          params: { param: item, module: 'meeting2_notice' },
        })
        break
      case 'meeting2_remind':
        that.$router.push({
          name: 'meetingManagement',
          params: { param: item, module: 'meeting2_remind' },
        })
        break
      case 'sealApply':
        that.$router.push({
          name: 'sealApplication',
          params: { id: item.id },
        })
        break
      case 'task1':
        that.$router.push({ name: 'problemReport', params: { id: item.id } })
        break
      case 'task2':
        that.$router.push({ name: 'task', params: { id: item.id } })
        break
      case 'dispatch':
        that.$router.push({ name: 'dispatch', params: { id: item.id } })
        break
      case 'daily':
        that.$router.push({ name: 'daily', params: { id: item.id } })
        break
      case 'td':
        that.$router.push({ name: 'technical', params: { id: item.id } })
        break
      case 'technical_disclosure':
        that.$router.push({ name: 'technical', params: { id: item.id } })
        break
      // unable  item 可能参数不全
      case 'gantt_mission':
        that.$router.push({
          name: 'dispatchDetail',
          params: {
            id: item.id,
            gantt_plan_id: item.gantt_plan_id,
            type: 'gantt_mission',
          },
        })
        break
      case 'gantt_plans':
        that.$router.push({
          name: 'dispatchDetail',
          params: { id: item.id, type: 'gantt_plans' },
        })
        break
      case 'acceptance':
        that.$router.push({
          name: 'procedureInspection',
          params: { id: item.id },
        })
        break
      case 'pre_shift_meetings':
        that.$router.push({
          name: 'meetingBefore',
          params: { id: item.id },
        })
        break
      case 'material_scheduling':
        that.$router.push({
          name: 'schedulingList',
          params: { id: item.id },
        })
        break
      case 'foundation_pit_monitors':
        that.$router.push({
          name: 'foundationPit',
          params: { id: item.id },
        })
        break
      case 'week_report':
        that.$router.push({
          name: 'dailyReport',
          params: { id: item.id },
        })
        break
      case 'space_approve':
        that.$router.push({
          name: 'newDisk',
          query: {
            type: 6,
            approve_type_id: item.id,
            route: that.route,
            isJump: true,
          },
        })
        break
      case 'equipment':
        that.$router.push({
          name: 'eqEnter',
          params: {
            id: item.id,
            type: 'eqEnter',
          },
        })
        break
      case 'design_drawing_check':
        that.$router.push({
          name: 'reviewOpinion',
          params: { id: item.id },
        })
        break
      case 'design_service':
        that.$router.push({
          name: 'designService',
          params: { id: item.id },
        })
        break
      case 'equipment_inspect':
        that.$router.push({
          name: 'eqInspection',
          params: {
            id: item.id,
            type: 'eqInspection',
          },
        })
        break
      case 'equipment_patrol_log':
        that.$router.push({
          name: 'eqPatrol',
          params: {
            id: item.id,
            type: 'eqPatrol',
          },
        })
        break
      case 'highlight':
        that.$router.push({
          name: 'proHighlight',
          params: { id: item.id },
        })
        break
      // case "assignment":
      //   that.$router.push({
      //     name: "workLog",
      //     params: { id: item.id }
      //   })
      //   break;
      case 'build_log':
        // console.log("详情", item);
        that.$router.push({
          name: 'BuildlogList',
          params: { id: item.id },
        })
        break
      case 'supervisors':
        that.$router.push({
          name: 'supervisionLog',
          params: { id: item.id },
        })
        break
      case 'sa_log':
        that.$router.push({
          name: 'salog',
          params: { id: item.id },
        })
        break
      case 'supervisor_safety':
        that.$router.push({
          name: 'supSafetyLog',
          params: { id: item.id },
        })
        break
      case 'equipment_maintenance':
        that.$router.push({
          name: 'eqMaintenance',
          params: {
            id: item.id,
            type: 'eqMaintenance',
          },
        })
        break
      case 'demolition':
        that.$router.push({
          name: 'demolition',
          params: { id: item.id },
        })
        break
      case 'hr_user_ask_leave':
        that.$router.push({
          name: 'leaveApplication',
          params: { id: item.id },
        })
        break
      case 'user_ask_leave':
        that.$router.push({
          name: 'leaveApplication',
          params: { id: item.id },
        })
        break
      case 'hr_business_trip':
        that.$router.push({
          name: 'travelApplication',
          params: { id: item.id },
        })
        break
      case 'cancel_leave':
        that.$router.push({
          name: 'leaveApplication',
          params: { id: item.id },
        })
        break
      case 'equipment_scrap_normal': {
        that.$router.push({
          name: 'eqScrapping',
          params: { id: item.id, type: item.module_name },
        })
        break
      }
      case 'equipment_scrap_abnormal': {
        that.$router.push({
          name: 'eqScrapping',
          params: { id: item.id, type: item.module_name },
        })
        break
      }

      case 'material_scrap': {
        that.$router.push({
          name: 'maScrapping',
          params: { id: item.id },
        })
        break
      }
      case 'Questionnaire': {
        // console.log(item,'路由的item')
        that.$router.push({
          name: 'questionnaire',
          params: { id: item.id },
        })
        break
      }
      case 'work_flow_section_approval': {
        that.$router.push({
          name: 'workWeekly',
          params: { id: item.id, module: 'work_flow_section_approval' },
        })
        break
      }
      case 'work_flow_all_approval': {
        that.$router.push({
          name: 'workWeekly',
          params: { id: item.id, module: 'work_flow_all_approval' },
        })
        break
      }
      case 'work_log': {
        that.$router.push({
          name: 'workWeekly',
          params: { id: item.id, module: 'work_log' },
        })
        break
      }
      case 'new_weekly_flow_section': {
        that.$router.push({
          name: 'workWeeklyNew',
          params: { id: item.id, module: 'new_weekly_flow_section' },
        })
        break
      }
      case 'new_weekly_flow_manage': {
        that.$router.push({
          name: 'workWeeklyNew',
          params: { id: item.id, module: 'new_weekly_flow_manage' },
        })
        break
      }
      case 'new_weekly': {
        that.$router.push({
          name: 'addWorkWeeklyNew',
          params: { id: item.id, module: 'new_weekly' },
        })
        break
      }
      case 'returnBusinessTrip':
        that.$router.push({
          name: 'travelApplication',
          params: { id: item.id },
        })
        break
      case 'car_apply':
        that.$router.push({
          name: 'carsApplication',
          params: { id: item.id },
        })
        break
      case 'monitor_daily':
        that.$router.push({
          name: 'monitorDairy',
          query: { id: item.id },
        })
        break
      case 'pwg_news':
        that.$router.push({
          name: 'editPwgNews',
          query: { id: item.id },
        })
        break
      case 'company_pwg_news':
        that.$router.push({
          name: 'editPwgNews',
          query: { id: item.id },
        })
        break
      case 'material_income_expenditure':
        that.$router.push({
          name: 'balance18',
          params: { id: item.id },
        })
        break
      case 'equipment_exit':
        that.$router.push({
          name: 'eqExit',
          params: { id: item.id, type: 'eqExit' },
        })
        break
      case 'official_document2':
        that.$router.push({
          name: 'documentmanage',
          params: { id: item.id },
        })
        break
      case 'official_document2_send':
        that.$router.push({
          name: 'documentmanage',
          params: { id: item.id, sendFlag: true },
        })
        break
      case 'capital_plan':
        that.$router.push({
          name: 'capitalPlan',
          params: { id: item.id },
        })
        break
      case 'safety_assess':
        that.$router.push({
          name: 'evaluationReport',
          params: { id: item.id },
        })
        break
      case 'design_review':
        that.$router.push({
          name: 'designReview',
          params: { id: item.id },
        })
        break
      case 'security_investment_expenditure': {
        that.$router.push({
          name: 'safetyInvestment',
          params: { id: item.id, active: 'security_investment_expenditure' },
        })
        break
      }
      case 'security_investment_plan': {
        that.$router.push({
          name: 'safetyInvestment',
          params: { id: item.id, active: 'security_investment_plan' },
        })
        break
      }
      case 'emergency_organization_apply': {
        that.$router.push({
          name: 'emergencyOrganization',
          params: { id: item.id, active: 'EmergencyOrganization' },
        })
        break
      }
      case 'emergency_team_apply': {
        that.$router.push({
          name: 'emergencyOrganization',
          params: { id: item.id, active: 'EmergencyTeam' },
        })
        break
      }
      case 'responsibility_log': {
        that.$router.push({
          name: 'objectivesRespon',
          params: { id: item.id, active: 'true' },
        })
        break
      }
      case 'work_plan': {
        that.$router.push({
          name: 'objectivesRespon',
          params: { id: item.id, active: 'false' },
        })
        break
      }
      case 'emergency_material_apply': {
        that.$router.push({
          name: 'emergencySupplies',
          params: { id: item.id, active: 'true' },
        })
        break
      }
      case 'energy_save': {
        that.$router.push({
          name: 'energyConservationAndEcologicalEnvironment',
          params: { id: item.id, active: 'true' },
        })
        break
      }
      case 'emergency_drill': {
        that.$router.push({
          name: 'emergency-drill',
          params: { id: item.id },
        })
        break
      }
      case 'emergency_reserve_plan': {
        that.$router.push({
          name: 'emergency-plan',
          params: { id: item.id },
        })
        break
      }
      case 'emergency_reserve_plan_assessment': {
        that.$router.push({
          name: 'emergency-plan',
          params: { id: item.id, assessmentFlag: true },
        })
        break
      }
      case 'quality_plan': {
        that.$router.push({
          name: 'qualityPlan',
          params: { id: item.id },
        })
        break
      }
      case 'task_supervision': {
        that.$router.push({
          name: 'testHandle',
          params: { id: item.id },
        })
        break
      }
      case 'contract_sub_settlement': {
        that.$router.push({
          name: 'subSettlement',
          params: { id: item.id },
        })
        break
      }
      case 'cons_service_apply_1': {
        that.$router.push({
          name: 'serviceAudit',
          query: { id: item.id, archi_id: item.archi_id },
        })
        break
      }
      case 'cons_service_apply_2': {
        that.$router.push({
          name: 'serviceAudit',
          query: { id: item.id, archi_id: item.archi_id },
        })
        break
      }
      case 'marketing_briefing': {
        that.$router.push({
          name: 'marketOperation',
          params: { id: item.id },
        })
        break
      }
      case 'contract_ci_claimant': {
        that.$router.push({
          name: 'constractClaims',
          params: { id: item.id },
        })
        break
      }
      case 'qa_quality': {
        that.$router.push({
          name: 'qualityPolling',
          query: { id: item.id },
        })
        break
      }
      case 'iot_power_box_equipment_out': {
        that.$router.push({
          name: 'distributionBoxManagement',
          query: { id: item.id, type: 'iot_power_box_equipment_out' },
        })
        break
      }
      case 'iot_power_box_equipment_maintenance': {
        that.$router.push({
          name: 'distributionBoxManagement',
          query: { id: item.id, type: 'iot_power_box_equipment_maintenance' },
        })
        break
      }
      case 'iot_power_box_equipment': {
        that.$router.push({
          name: 'distributionBoxManagement',
          query: { id: item.id, type: 'iot_power_box_equipment' },
        })
        break
      }
      case 'change_manage': {
        that.$router.push({
          name: 'changeManagementList',
          query: { id: item.id },
        })
        break
      }
      case 'material_apply2': {
        that.$router.push({
          name: 'applyforYear18',
          query: { id: item.id },
        })
        break
      }
      case 'dp_judge': {
        that.$router.push({
          name: 'specialPlan',
          params: { id: item.id, archiID: item.archi_id },
        })
        break
      }
      case 'dp_hand_over': {
        that.$router.push({
          name: 'clarification',
          params: { id: item.id, archiID: item.archi_id },
        })
        break
      }
      case 'dp_dynamic_judge': {
        that.$router.push({
          name: 'dynamicVote',
          params: { id: item.id, archiID: item.archi_id },
        })
        break
      }
      case 'dp_acceptance': {
        that.$router.push({
          name: 'dangerCheck',
          params: { id: item.id, archiID: item.archi_id },
        })
        break
      }
      case 'dp_cancel': {
        that.$router.push({
          name: 'dangerCancel',
          params: { id: item.id, archiID: item.archi_id },
        })
        break
      }
      case 'dp_change': {
        that.$router.push({
          name: 'dangerChange',
          params: { id: item.id, archiID: item.archi_id },
        })
        break
      }
      case 'technology_inspection': {
        that.$router.push({
          name: 'technicalInspection',
          params: { id: item.id },
        })
        break
      }
      case 'crt15_daily_testing': {
        that.$router.push({
          name: 'dailyMonitoring',
          params: { id: item.id },
        })
        break
      }
      case 'crt15_environment_testing': {
        that.$router.push({
          name: 'environmentalInspection',
          params: { id: item.id },
        })
        break
      }
      // default:
      //   let path = "/pc/formModule/" + val;
      //   that.$router.push({ path: path, query: { id: item.id } });
      //   break;
    }
  }
}
