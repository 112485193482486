<!--
 * @Description: 弹出层
 * @Author: luocheng
 * @Date: 2021-07-23 09:44:51
 * @LastEditors: luocheng
 * @LastEditTime: 2021-07-23 11:38:46
-->
<template>
  <div class="popup" :style="[boxPositon]">
    <div v-if="infoData.id !== -1" class="container">
      详情{{ infoData }}
    </div>
    <div v-else class="container">
      <!-- 新增 {{ infoData }} -->
      <AddTask :infoData="infoData" />
    </div>
  </div>
</template>

<script>
import AddTask from './AddTask'
export default {
  name: 'Popup',
  components: { AddTask },
  props: {
    // 位置
    positon: {
      type: Object,
      default: () => {},
      required: true
    },
    infoData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    // 位置
    boxPositon() {
      const { left = 0, top = 0 } = this.positon;
      return {
        left: left + 'px',
        top: top + 'px'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.popup{
  position: absolute;
  width: 400px;
  height: 300px;
  overflow: hidden;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #d9dce1;
  overflow: hidden;
  z-index: 999999;
  box-shadow: rgba(0,0,0,.2)  0 1px 5px 0px;
  .container{
    width: 100%;
    padding: 5px;
  }
}
</style>