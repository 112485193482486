<!--
 * @Description: 小日历组件
 * @Author: luocheng
 * @Date: 2021-07-15 15:54:26
 * @LastEditors: luocheng
 * @LastEditTime: 2021-07-22 11:20:08
-->
<template>
  <div class="calendar">
    <Header @updateDate="getDate" :defaultData="dateValue"></Header>
    <article class="main">
      <header class="header">
        <section class="sign" v-for="(sign, signIndex) in signList" :key="signIndex">
          {{ sign }}
        </section>
      </header>
      <article class="content" :class="{'week-content': type === 'week'}">
        <!-- 日期选择器 -->
        <template v-if="type === 'date' || type === 'month'">
          <section class="item" v-for="item in dateList" :key="item.date"
            :class="{
              'disable': item.disabled,
              'not-show': item.disabled && !fill,
              'current': value === item.date,
              'is-today': showToday && item.isToday
            }"
            :style="{
              height: markOption && markOption.show ? '30px' : '20px'
            }"
            @click="onGetDay(item)"
          >
            {{ showToday && item.isToday ? '今' : item.label }}
            <p class="point" v-if="markOption && markOption.show && markOption.handle(item).showPoint"
              :class="{
                'highlight': markOption.handle(item).status
              }"
            ></p>
          </section>
        </template>
        <!-- 周选择器 -->
        <template v-else-if="type === 'week'">
          <section class="week-item" v-for="weekItem in weekList" :key="weekItem.index"
            @click="onGetDay(weekItem.days[0], weekItem.index)"
            :class="{
              'current-week': currentWeek === weekItem.index
            }"
            :style="{
              height: markOption && markOption.show ? '32px' : '22px'
            }"
          >
            <section class="item" v-for="item in weekItem.days" :key="item.date"
              :class="{
                'disable': item.disabled,
                'is-today': showToday && item.isToday
              }"
              :style="{
                height: markOption && markOption.show ? '30px' : '20px'
              }"
            >
              {{ showToday && item.isToday ? '今' : item.label }}
              <!-- 点 -->
              <p class="point" v-if="markOption && markOption.show"
                :class="{
                  'highlight': markOption.handle(item)
                }"
              ></p>
            </section>
          </section>
        </template>
      </article>
    </article>
  </div>
</template>

<script>
import Header from './Header';

export default {
  name: 'Calendar',
  props: {
    // 绑定的值
    value: {
      type: String,
      default: '',
      required: true
    },
    // 类型 date 正常日期 week 周选择器
    type: {
      type: String,
      default: 'date',
      required: false
    },
    // 头部标志位
    signList: {
      type: Array,
      default: () => ['一', '二', '三', '四', '五', '六', '日'],
      required: false
    },
    // 是否显示不属于本月的月份进行补齐(暂时无此需求，容后补充)
    fill: {
      type: Boolean,
      default: true,
      required: false
    },
    // 关于小圆点的显示规则
    markOption: {
      type: Object,
      default: () => {
        return {
          show: false,
          handle: () => {}
        }
      },
      required: true
    },
    // 是否显示今天标记
    showToday: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  components: {
    Header
  },
  data() {
    return {
      // 年月
      dateValue: '',
      // 日期列表
      dateList: [],
      // 大月 31天
      largeMonths: [1, 3, 5, 7, 8, 10, 12],
      smallMonths: [4, 6, 9, 11],
      // 今天
      todayDate: '',
      // 周列表二维数组
      weekList: [],
      // 当前所选周
      currentWeek: -1,
    }
  },
  created() {
    if (this.showToday) {
      const today = new Date();
      this.todayDate = `${today.getFullYear()}/${today.getMonth() + 1}/${today.getDate()}`;
      this.onGetDay({
        date: this.todayDate
      });
    }
  },
  watch: {
    // 监听日历类型变化
    type(newv) {
      // console.log(newv, '----');
      if (newv === 'month') {
        // 初始值
        this.currentWeek = -1;
      }
      // 月
      this.dateValue = this.value;
      this.setCalendar();
    },
    // 监听value变化
    value(newv) {
      // console.log(newv, this.dateValue, '--监听value变化-')
      if (newv === this.dateValue) return;
      this.dateValue = newv;
      this.setCalendar();
    }
  },
  methods: {
    /**
     * @desc: 设置新的日历
     * @param {*}
     * @return {*}
     */
    setCalendar() {
      this.dateList = [];
      if (typeof this.dateValue !== 'string' || !this.dateValue.split('/').length) return;
      const dateArr = this.dateValue.split('/');
      if (isNaN(+dateArr[0]) || isNaN(+dateArr[1])) return;
      const year = +dateArr[0];
      const month = +dateArr[1];
      // 月的情况较特殊
      // const len = 7; // 一周有几天
      const startDay = `${year}/${month}/1 00:00:00`; // 开始日
      const dayCount = this.getDayCount(year, month); // 一个月有多少天
      const startWeekDay = (new Date(startDay)).getDay() === 0 ? 7 : (new Date(startDay)).getDay(); // 开始星期几大于0表示需要前补几天
      // 每个月最多的情况会出现会出现6周的情况 即月历中需要显示42天
      const total = 42;
      // 获取上一月的数据预填充
      if (startWeekDay > 0) {
        const prevArr = this.getPrevs(year, month, startWeekDay - 1);
        this.dateList = this.dateList.concat(prevArr);
      }
      // 当前月数据
      for (let i = 1; i <= dayCount; i++) {
        this.dateList.push({
          date: `${year}/${month}/${i}`,
          label: i, // 日历中显示的日期
          disabled: false, // 是否可以操作 上月下月均不可操作
          year: year,
          month: month,
          day: i,
          weekDay: new Date(`${year}/${month}/${i}`).getDay(), // 周几 
          timestamp: new Date(`${year}/${month}/${i} 00:00:00`).getTime(),
          isToday: this.showToday ? this.todayDate === `${year}/${month}/${i}` : false
        });
      }
      // 获取后一个月数据并进行填充
      if (total - this.dateList.length > 0) {
        const nextArr = this.getNexts(year, month, total - this.dateList.length);
        this.dateList = this.dateList.concat(nextArr);
      }
      // 周
      if (this.type === 'week') {
        this.weekList = [];
        let weekItem = [];
        this.currentWeek === -1;
        let index = 1;
        for (let i = 0; i <= this.dateList.length; i++) {
          if (i % 7 === 0 && i !== 0) {
            index++;
            this.weekList.push({
              index,
              days: weekItem,
              startDay: weekItem[0]
            });
            weekItem = []
          }
          // 设置当前周
          if (i < this.dateList.length && this.dateList[i].date === this.value) {
            this.currentWeek = index + 1;
          }
          weekItem.push(this.dateList[i]);
        }
        // console.log(this.weekList, '周列表')
      }
    },
    /**
     * @desc: 获取前一个月数据
     * @param {*} year 当前年份) number
     * @param {*} month 当前月份 number
     * @param {*} count 需要补充几天 number
     * @return {*} 上月数据数组
     */
    getPrevs(year, month, count) {
      const prevYear = month === 1 ? year - 1 : year;
      const prevMonth = month === 1 ? 12 : month - 1;
      const days = this.getDayCount(prevYear, prevMonth);
      const result = [];
      for (let i = days - count + 1 ; i <= days; i++) {
        result.push({
          date: `${prevYear}/${prevMonth}/${i}`,
          label: i, // 日历中显示的日期
          disabled: true, // 是否可以操作 上月下月均不可操作
          year: prevYear,
          month: prevMonth,
          day: i,
          weekDay: new Date(`${prevYear}/${prevMonth}/${i}`).getDay(), // 周几 
          timestamp: new Date(`${year}/${month}/${i} 00:00:00`).getTime(),
          isToday: false
        });
      }
      return result;
    },
    /**
     * @desc: 获取下一个月数据
     * @param {*} year 当前年份) number
     * @param {*} month 当前月份 number
     * @param {*} count 需要追加几天 number
     * @return {*} 下月数据数组
     */
    getNexts(year, month, count) {
      const nextYear = month === 12 ? year + 1 : year;
      const nextMonth = month === 12 ? 1 : month + 1;
      // const days = this.getDayCount(nextYear, nextMonth);
      const result = [];
      for (let i = 1 ; i <= count; i++) {
        result.push({
          date: `${nextYear}/${nextMonth}/${i}`,
          label: i, // 日历中显示的日期
          disabled: true, // 是否可以操作 上月下月均不可操作
          year: nextYear,
          month: nextMonth,
          day: i,
          weekDay: new Date(`${nextYear}/${nextMonth}/${i}`).getDay(), // 周几 
          timestamp: new Date(`${year}/${month}/${i}`).getTime(),
          isToday: false
        });
      }
      return result;
    },
    /**
     * @desc: 判断当前为大月小月，或者2月闰月 返回当月天数
     * @param {*} year 当前年 number
     * @param {*} month 当前月 number
     * @return {*}
     */
    getDayCount(year, month) {
      if (month === 2) {
        // 2月需要判断平年 闰年
        if (this.isLeapYear(year)) return 29;
        return 28;
      } 
      if (this.largeMonths.includes(month)) return 31;
      if (this.smallMonths.includes(month)) return 30;
    },
    /**
     * @desc: 判断是够为闰年 闰年能被4整除且不能被100整除，或能被400整除
     * @param {*} year 年份 number
     * @return {*}
     */
    isLeapYear(year) {
      if (isNaN(year)) return false;
      return (year % 4 === 0) && (year % 100 !== 0) || (year % 400 === 0);
    },
    /**
     * @desc: 获取当前所选月份
     * @param {*} date 当前年月 string
     * @return {*}
     */
    getDate(date) {
      if (date === this.dateValue) return;
      // console.log(date, 'datedate');
      this.dateValue = date;
      this.setCalendar();
    },
    /**
     * @desc: 选择日期
     * @param {*} item 当前所选日期/当前所选周
     * @param {*} curentWeek 当前所选周（仅周类型）
     * @return {*}
     */
    onGetDay(item, curentWeek = -1) {
      if (this.type === 'week') {
        this.currentWeek = curentWeek;
      }
      if (this.type === 'date' && item.disabled) return;
      this.$emit('input', item.date);
    }
  }
}
</script>

<style lang="less" scoped>
@boxWidth: 220px;
@itemHeight: 20px;
@itemWidth: 30px;
@iotHeight: 4px;
.calendar{
  height: auto;
  width: @boxWidth;
  // background: #f2f3f5;
  box-sizing: border-box;
  padding: 5px;
  margin: auto;
  .main{
    box-sizing: border-box;
    padding-top: 16px;
    .header{
      display: flex;
      height: @itemHeight;
      .sign{
        width: @itemWidth;
        text-align: center;
        line-height: @itemHeight;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #202126;
        line-height: 20px;
        cursor: pointer;
        &:nth-of-type(7n -1) {
          color: #8a8f99;
        }
        &:nth-of-type(7n) {
          color: #8a8f99;
        }
      }
    }
    .content{
      display: flex;
      flex-wrap: wrap;
      padding: 2px 0;
      width: 100%;
      .item{
        position: relative;
        margin-top: 6px;
        width: @itemWidth;
        height: @itemHeight + @iotHeight;
        text-align: center;
        line-height: @itemHeight;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #202126;
        line-height: 20px;
        cursor: pointer;
        border-radius: 6px;
        padding-top: 2px;
        transform: all .1s;
        &:nth-of-type(7n) {
          color: #8a8f99;
        }
        &:nth-of-type(7n - 1) {
          color: #8a8f99;
        }
        &.not-show{
          opacity: 0;
          &:hover{
            background: transparent;
            color: transparent;
          }
        }
        &.disable{
          color: #8a8f99;
          &:hover{
            background: #f2f3f5;
          }
        }
        &.current{
          background: rgba(0,129,153,0.20);
        }
        &.is-today{
          color: #008199;
          font-weight: bold;
        }
        &:hover{
          background: #008199;
          color: #fff;
        }
        // 点
        .point{
          height: @iotHeight;
          width: @iotHeight;
          position: absolute;
          bottom: 4px;
          left: calc(50% - 2px);
          margin: auto;
          background: #bdc0c7;
          border-radius: @iotHeight/2;
          &.highlight{
            background: #008199;
          }
        }
      }
      &.week-content{
        display: block;
      }
      // 周
      .week-item{
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        height: @itemHeight + @iotHeight;
        box-sizing: border-box;
        margin-top: 6px;
        padding-top: 2px;
        &:hover{
          background: rgba(0,129,153,0.20);
          border-radius: 4px;
        }
        &.current-week{
          background: rgba(0,129,153,0.20);
          border-radius: 4px;
        }
        .item{
          margin-top: 0;
          padding-top: 0;
          &:hover{
            background: transparent;
            color: #202126;
          }
          &.f{
            color: #008199;
            &:hover{
              color: #008199;
            }
          }
          &.disable{
            &:hover{
              color: #8a8f99;
            }
          }
          &:nth-of-type(7n) {
            &:hover{
              color: #8a8f99;
            }
          }
          &:nth-of-type(7n - 1) {
            &:hover{
              color: #8a8f99;
            }
          }
        }
      }
    }
  }
}
</style>