<template>
  <!-- 任务列表 -->
  <div class="task-list-container scrollCont">
    <el-scroll
      v-if="taskData && Array.isArray(taskData.data) && taskData.data.length"
    >
      <template v-for="(item, index) in taskData.data">
        <div :key="'tk' + index" class="task-item" @click="handleClick(item)">
          <div class="status-ic">
            <div
              class="status-icon"
              :class="[2, 5].includes(item.status) ? 'fin-status' : ''"
            ></div>
          </div>
          <div
            class="task-item-main"
            :class="[2, 5].includes(item.status) ? 'fin-font' : ''"
          >
            <div style="display: flex">
              <p v-if="item.priority" class="task-priority">
                {{ "!".repeat(item.priority) }} &nbsp;
              </p>
              <p class="task-title">{{ item.title }}</p>
            </div>
            <p>
              <span class="task-other-font">{{
                getModulName(item.module_name, item.object_name).typeName
              }}</span>
              <span class="task-other-font">&nbsp;•&nbsp;</span>
              <span class="task-other-font">
                {{ nameFormat(item.from_user_name) }} ＞
                {{ nameFormat(item.user_name) }}</span
              >
              <span
                class="task-other-font time-stamp"
                :class="deadLine(item).class"
                v-text="deadLine(item, item.endDate).time"
              ></span>
            </p>
          </div>
        </div>
      </template>
    </el-scroll>
    <!-- <emptyCont v-else text="暂无内容!"/> -->
  </div>
</template>

<script>
import { Scrollbar } from 'element-ui'
import { format } from "date-fns";
import { getModulName } from "@/components/todoList/main/modulAbout.js";
export default {
  props: {
    taskData: {
      type: Object,
      default () {
        return {
          data: [],
        };
      },
    },
  },
  data () {
    return {};
  },
   components: {
       'el-scroll':Scrollbar
  },
  methods: {
    // 格合化人名
    nameFormat (name) {
      if (name) {
        return name === this.currentUserName ? "我" : name;
      } else {
        return "-";
      }
    },
    getModulName (name, objName) {
      return getModulName(name, objName);
    },
    // 触发父级事件
    handleClick (item) {
      //if(this.$checkIfVersionFour()){
      let data = {
        event: "showDetailModal",
        form_uuid: item.form_id,
        flow_id: item.flow_id,
        data_id: item.id,
        module_name: item.module_name,
        object_name:item.object_name,
      };
      window.parent.postMessage(data, "*");
      return;
      //}
      // 原先打开方式
      // this.$emit('itemClick', item)
      // 暂时打开方式
      // this.$store.commit('setActivetaskData', item)
    },
    // 时间格式化
    deadLine (item) {
      const deadLine = item.endDate;
      let date1_s = new Date(deadLine.replace(/-/g, "/"));
      const nowTime = new Date();
      let dateDiff = nowTime.getTime() - date1_s.getTime();
      let date = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      if ([2, 5].includes(item.status)) {
        return {
          time: this.finishedTimeFormat(deadLine),
          class: "gray-time",
        };
      }
      if (nowTime.getTime() > date1_s.getTime()) {
        return {
          time: "逾期" + date + "天",
          class: "red-time",
          tag: true,
        };
      } else {
        return {
          time: this.timeFormat(item.endDate),
        };
      }
    },
    timeFormat (time) {
      if (!this.nowDateObj) {
        this.nowDateObj = new Date();
      }
      const targetDate = new Date(time);
      const ny = this.nowDateObj.getFullYear();
      const ty = targetDate.getFullYear();
      const nm = this.nowDateObj.getMonth();
      const tm = targetDate.getMonth();
      const nd = this.nowDateObj.getDate();
      const td = targetDate.getDate();
      if (ny === ty) {
        if (nm === tm) {
          if (nd === td) {
            return `今天${format(targetDate, "HH:mm")}截止`;
          } else if (td - nd === 1) {
            return `明天${format(targetDate, "HH:mm")}截止`;
          } else {
            return `${format(targetDate, "MM-dd HH:mm")}截止`;
          }
        } else {
          return `${format(targetDate, "MM-dd HH:mm")}截止`;
        }
      } else {
        return `${format(targetDate, "yyyy-MM-dd HH:mm:ss")}截止`;
      }
    },
    finishedTimeFormat (time) {
      if (!this.nowDateObj) {
        this.nowDateObj = new Date();
      }
      const targetDate = new Date(time);
      const ny = this.nowDateObj.getFullYear();
      const ty = targetDate.getFullYear();
      const nm = this.nowDateObj.getMonth();
      const tm = targetDate.getMonth();
      const nd = this.nowDateObj.getDate();
      const td = targetDate.getDate();
      if (ny === ty) {
        if (nm === tm) {
          if (nd === td) {
            return `今天${format(targetDate, "HH:mm")}`;
          } else if (nd - td === 1) {
            return `昨天${format(targetDate, "HH:mm")}`;
          } else {
            return `${format(targetDate, "MM-dd HH:mm")}`;
          }
        } else {
          return `${format(targetDate, "MM-dd HH:mm")}`;
        }
      } else {
        return `${format(targetDate, "yyyy-MM-dd HH:mm")}`;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.task-list-container {
  height: 100%;
  .task-item {
    cursor: pointer;
    margin-bottom: 12px;
    background: #fff;
    padding: 12px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    .status-ic {
      padding: 6px 10px 6px 6px;
      .status-icon {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: rgba(0, 129, 153, 1);
      }
      .fin-status {
        background: rgba(189, 192, 199, 1);
      }
    }
    .fin-font {
      .task-title {
        text-decoration: line-through;
        color: #8a8f99 !important;
      }
      .task-other-font {
        text-decoration: line-through;
      }
    }
    .task-item-main {
      flex-grow: 1;
      .task-priority {
        color: rgba(221, 44, 47, 1);
        font-size: 14px;
      }
      .task-title {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #202126;
        line-height: 22px;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .task-other-font {
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #8a8f99;
        line-height: 20px;
      }
      .time-stamp {
        float: right;
      }
    }
  }
}
</style>