import { axios } from './http/request';

const data = {}
export const getSectionApi = async (params) => {
    let url = 'api/sections/choose'
    if (params) {
        url += JSON.stringify(params)
    }
    // 判断是否存在，是否过期
    if (data[url] && data[url].exp > new Date().getTime() - 1000 * 60) {
        return new Promise((resolve) => {
            resolve(JSON.parse(JSON.stringify(data[url].data)))
        })
    } else {
        let flag = true
        let res = await axios.request({
            url: 'api/sections/choose',
            method: 'get',
            params
        })
        if (res && res.data.code === 200) {
            data[url] = {
                data: JSON.parse(JSON.stringify(res)),
                exp: new Date().getTime()
            }
        } else {
            flag = false
        }
        return new Promise((resolve, reject) => {
            if (flag)  {
                resolve(res)
            } else {
                reject(res)
            }
        })
    }
}

export const getRoleLabel = async (params) => {
    let url = 'api/archilabel/getRoleLabels'
    if (params) {
        url += JSON.stringify(params)
    }
    // 判断是否存在，是否过期
    if (data[url] && data[url].exp > new Date().getTime() - 1000 * 60) {
        return new Promise((resolve) => {
            resolve(JSON.parse(JSON.stringify(data[url].data)))
        })
    } else {
        let flag = true
        let res = await axios.request({
            url: 'api/archilabel/getRoleLabels',
            method: 'get',
            params
        })
        if (res && res.data.code === 200) {
            data[url] = {
                data: JSON.parse(JSON.stringify(res)),
                exp: new Date().getTime()
            }
        } else {
            flag = false
        }
        return new Promise((resolve, reject) => {
            if (flag)  {
                resolve(res)
            } else {
                reject(res)
            }
        })
    }
}

export const getUsers = async (params) => {
    let url = 'api/plan/transferUser'
    if (params) {
        url += JSON.stringify(params)
    }
    // 判断是否存在，是否过期
    if (data[url] && data[url].exp > new Date().getTime() - 1000 * 60) {
        return new Promise((resolve) => {
            resolve(JSON.parse(JSON.stringify(data[url].data)))
        })
    } else {
        let flag = true
        let res = await axios.request({
            url: 'api/plan/transferUser',
            method: 'get',
            params
        })
        if (res && res.data.code === 200) {
            data[url] = {
                data: JSON.parse(JSON.stringify(res)),
                exp: new Date().getTime()
            }
        } else {
            flag = false
        }
        return new Promise((resolve, reject) => {
            if (flag)  {
                resolve(res)
            } else {
                reject(res)
            }
        })
    }
}

export const getSections = async (params) => {
    let url = 'api/sections'
    if (params) {
        url += JSON.stringify(params)
    }
    // 判断是否存在，是否过期
    if (data[url] && data[url].exp > new Date().getTime() - 1000 * 60) {
        return new Promise((resolve) => {
            resolve(JSON.parse(JSON.stringify(data[url].data)))
        })
    } else {
        let flag = true
        let res = await axios.request({
            url: 'api/sections',
            method: 'get',
            params
        })
        if (res && res.data.code === 200) {
            res.data.data.map(el => {
                el.label = el.name
                el.value = el.id
            })
            data[url] = {
                data: JSON.parse(JSON.stringify(res)),
                exp: new Date().getTime()
            }
        } else {
            flag = false
        }
        return new Promise((resolve, reject) => {
            if (flag)  {
                resolve(res)
            } else {
                reject(res)
            }
        })
    }
}
export const getUsersOptions = async (params) => {
    let url = 'api/user/choose'
    if (params) {
        url += JSON.stringify(params)
    }
    // 判断是否存在，是否过期
    if (data[url] && data[url].exp > new Date().getTime() - 1000 * 60) {
        return new Promise((resolve) => {
            resolve(JSON.parse(JSON.stringify(data[url].data)))
        })
    } else {
        let flag = true
        let res = await axios.request({
            url: 'api/user/choose',
            method: 'get',
            params
        })
        if (res && res.data.code === 200) {
            const rs = []
            const handle = (data) => {
                data.map(el => {
                    if ('wechat_avatar' in el) {
                        const v = {role_id: el.value[1], user_id: el.value[0]}
                        el.value = v
                        rs.push(el)
                    }
                    if (Array.isArray(el.children) && el.children.length !== 0) {
                        handle(el.children)
                    }
                })
            }
            handle(res.data.data)

            res.data.data = rs
            data[url] = {
                data: JSON.parse(JSON.stringify(res)),
                exp: new Date().getTime()
            }
        } else {
            flag = false
        }
        return new Promise((resolve, reject) => {
            if (flag)  {
                resolve(res)
            } else {
                reject(res)
            }
        })
    }
}
export const getSafetyCommitteeList = (params) => {
    return axios.request({
        url: 'api/safetyCommittee',
        method: 'get',
        params
    })
}

export const addSafetyCommittee = (data) => {
    return axios.request({
        url: 'api/safetyCommittee',
        method: 'post',
        data
    })
}

export const modifySafetyCommittee = (id, data) => {
    return axios.request({
        url: `api/safetyCommittee/${id}`,
        method: 'put',
        data
    })
}

export const deleteSafetyCommittee = (id) => {
    return axios.request({
        url: `api/safetyCommittee/${id}`,
        method: 'delete'
    })
}

// 架构 
export const getFrameWorks = (params) => {
    return axios.request({
        url: 'api/diagram/institutions',
        method: 'get',
        params
    })
}

// 获取责任体系列表
export const getInstituions = () => {
    return axios.request({
        url: 'api/institutions',
        method: 'get'
    })
}

// 架构新增
export const addFrameWork = (data) => {
    return axios.request({
        url: 'api/diagram/institutions',
        method: 'post',
        data
    })
}
// 编辑
export const editorFrameWork = (id,data) => {
    return axios.request({
        url: `api/diagram/institutions/${id}`,
        method: 'put',
        data
    })
}

// 获取用户信息
export const getUserMsg = (ids = []) => {
    return axios.request({
        url: 'api/institutions/users',
        method: 'get',
        params: {
            user_ids: ids
        }
    })
}

// 获取角色信息
export const getRoleMsg = (ids = []) => {
    return axios.request({
        url: 'api/institutions/roles',
        method: 'get',
        params: {
            role_ids: ids
        }
    })
}

// 岗位职责上传模板
export const postOBupload = (data) => {
    return axios.request({
        url: 'api/responsibilities/import',
        method: 'post',
        data
    })
}

// 岗位职责库列表

export const getJobResponiblityDatabaseList = (params) => {
    return axios.request({
        url: 'api/responsibilities',
        method: 'get',
        params
    })
}

// 岗位职责库编辑

export const editorJobResponiblityDatabase = (id, data) => {
    return axios.request({
        url: 'api/responsibilities/' + id,
        method: 'put',
        data
    })
}

// 岗位职责库删除条目

export const deleteJobResponiblityDatabase = (id) => {
    return axios.request({
        url: 'api/responsibilities/' + id,
        method: 'delete'
    })
}

// 岗位职责列表

export const getJobResponsibilitiesList = (params) => {
    return axios.request({
        url: 'api/responsibilityLogs',
        method: 'get',
        params
    })
}
// 岗位职责条目编辑
export const editorJobResponsibilitiesItem = (id,data) => {
    return axios.request({
        url: 'api/responsibilityLogs/' + id,
        method: 'put',
        data
    })
}

// 岗位职责整改

export const rectifyJobResponsiblitiesItem = (id, data) => {
    return axios.request({
        url: 'api/responsibilityLogs/' + id + '/rectify',
        method: 'put',
        data
    })
}
// 岗位职责统计

export const JobResponsibilitiesStatistical = (params) => {
    return axios.request({
        url: 'api/responsibilityLogs/statistics',
        method: 'get',
        params
    })
}

// 岗位审批

export const JobResponsibilitiesExamine = (data) => {
    return axios.request({
        url: 'api/responsibilityLogs/operate',
        method: 'post',
        data
    })
}

// 获取详细信息
export const JobResponsibilitiesDetDetaile = (id) => {
    return axios.request({
        url: 'api/responsibilityLogs/' + id,
        method: 'get'
    })
}

// 工作计划导入

export const postWPupload = (data) => {
    return axios.request({
        url: 'api/assignmentWorkPlans/import',
        method: 'post',
        data
    })
}

// 工作计划列表查询
export const workPlansList = (params) => {
    return axios.request({
        url: 'api/assignmentWorkPlans',
        method: 'get',
        params
    })
}

// 工作计划导入模板下载
export const getWPImportTemplate = () => {
    return axios.request({
        url: 'api/assignmentWorkPlans/template',
        method: 'get'
    })
}

// 工作计划新增
export const newWorkPlan = (data) => {
    return axios.request({
        url: 'api/assignments',
        method: 'post',
        data
    })
}

// 工作计划详情
export const workPlanDetlies = (id) => {
    return axios.request({
        url: 'api/assignments/' + id,
        method: 'get'
    })
}

// 编辑工作计划
export const editorWorkPlan = (id, data) => {
    return axios.request({
        url: 'api/assignments/' + id,
        method: 'put',
        data
    })
}
// 操作工作计划
export const operateWorlPlan = (id, data) => {
    return axios.request({
        url: `api/assignments/${id}/operate`,
        method: 'put',
        data
    })
}
// 工作计划分配
export const WorkplanDistribution = (data) => {
    return axios.request({
        url: 'api/assignmentWorkPlans/assign',
        method: 'put',
        data
    })
}
// 工作计划审批
export const workPlanExamine = (data) => {
    return axios.request({
        url: 'api/assignments/processOperate',
        method: 'post',
        data
    })
}

//工作计划删除
export const workPlanDelete = (id) => {
    return axios.request({
        url: 'api/assignments/' + id,
        method: 'delete'
    })
}

//编辑安管人员台账
export const editorSafetyManagement = (id, data) => {
    return axios.request({
        url: `api/hr/user/${id}`,
        method: 'put',
        data
    })
}