<template>
  <div class="aside">
    <!-- 侧边栏 -->
    <div class="sider-top-container">
      <!-- headerSearch -->
      <div class="header-search">
        <el-input
          style="border-radius: 6px; background: #f2f3f5"
          class="search"
          v-model="searchVal"
          suffix-icon="el-icon-search"
          placeholder="搜索"
        ></el-input>
      </div>
      <!-- 列表区域 -->
      <div class="list-container">
        <template v-for="(item, index) in taskList">
          <div
            class="list-item"
            :class="activeItemIndex === index ? 'active-item' : ''"
            :key="'item' + index"
            @click="handleItemClick(item, index)"
          >
            <div class="item-icon">
              <i class="iconfont" :class="item.icon"></i>
            </div>
            <div class="item-text">
              <span>
                {{ item.title }}
              </span>
            </div>
            <div v-show="item.num" class="item-num">
              <span v-show="item.num" class="item-num-span">{{
                item.num
              }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 挂载计算属性getter -->
    <div style="width: 0; height: 0; overflow: hidden">{{ todolistBase }}</div>
    <br /><br />
    <!-- 小日历 -->
    <Calendar
      v-if="calendarType !== 'list'"
      v-model="currentDay"
      :todoList="todoList"
      :markOption="markOption"
      :type="calendarType"
    ></Calendar>
  </div>
</template>

<script>
import { Input, } from 'element-ui'
import eventBus from '@/plugins/eventBus';
import Calendar from './calendar/Index.vue'
export default {
  name: 'Aside',
  components: {
    'el-input': Input,
    Calendar,
  },
  props: {
    // 日历类型
    calendarType: {
      type: String,
      default: 'date',
      required: false
    },
    // 默认值(右边区域选择的值)
    defaultDate: {
      type: Number,
      default: 0,
      required: false
    }
  },
  mounted () {
    this.todoList = JSON.parse(JSON.stringify(this.$store.getters.getTodoList))
  },
  computed: {
    // 菜单
    taskList () {
      return this.$store.getters.getIndexMenuList || []
    },
    // 活动小项
    activeItemIndex () {
      return this.$store.getters.getActiveIndex || 0
    },
    // 任务统计
    todolistBase () {
      const data = this.$store.getters.getTodoList
      //this.todoList = JSON.parse(JSON.stringify(this.$store.getters.getTodoList))
      return data
    }
  },
  data () {
    return {
      currentDay: '',
      // 对应的待办事项数组
      todoList: [],
      // 判断是否显示点的方法
      markOption: {
        show: true,
        handle: (val) => {
          for (let i = 0; i < this.todoList.length; i++) {
            if (new Date(this.todoList[i].date).getTime() === new Date(val.date).getTime()) {
              const todos = this.todoList[i].todos;
              return {
                showPoint: todos.length === 0 ? false : true, // 是否存在任务
                status: todos.some(item => item.done === false) // 是否全部已完成
              }
            }
          }
          return false;
        }
      },
      // 右边所选的值
      rightVal: '',
      // 顶部搜索
      searchVal: '',
    }
  },
  watch: {
    // 当前所选日期
    currentDay (newv) {
      // console.log(newv, '---选择了日期');
      eventBus.$emit('calendarChange', new Date(`${newv} 00:00:00`).getTime())
    },
    // 右边选择的值（时间戳）
    defaultDate (newv) {
      // console.log(newv, '-----');
      if (isNaN(newv) || !newv) return;
      const defaultVal = new Date(newv);
      this.currentDay = `${defaultVal.getFullYear()}/${defaultVal.getMonth() + 1}/${defaultVal.getDate()}`;
    }
  },
  methods: {
    // 检索图标点击
    handleSearchClick () {
      if (this.searchVal) {
        const data = this.taskList[0].data.filter(el => el.title.includes(this.searchVal))
        this.$store.commit('setActiveItemIndex', -1)
        this.$store.commit('setActiveItem', {
          data: data
        })
      } else {
        this.$store.commit('setActiveItemIndex', 0)
        this.$store.commit('setActiveItem', this.taskList[0])
      }
    },
    //  change事件触发检索
    handleSearch () {
      if (this.searchVal) {
        const data = this.taskList[0].data.filter(el => el.title.includes(this.searchVal))
        this.$store.commit('setActiveItemIndex', -1)
        this.$store.commit('setActiveItem', {
          data: data
        })
      } else {
        this.$store.commit('setActiveItemIndex', 0)
        this.$store.commit('setActiveItem', this.taskList[0])
      }
    },
    // 小项点击事件
    handleItemClick (item, index) {
      // 存储活动项和活动下标
      if (item.type !== '-1') {
        this.$store.commit('setActiveItemIndex', index)
        this.$store.commit('setActiveItem', item)
      } else {
        // 已完成不存储活动项
        this.$store.commit('setActiveItemIndex', index)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@width: 270px;
.aside {
  height: 100%;
  width: @width;
  border-right: 1px solid #eaebed;
  // border-left: 1px solid #eaebed;
  background: #fff;
  box-sizing: border-box;
  padding: 0 12px 0 0;
}
</style>
<style lang="less" scoped>
.sider-top-container {
  border-bottom: 1px solid #f5f5f5;
  width: 100%;
  height: 50%;
  .header-search {
    width: 100%;
    padding: 0 12px 12px 12px;
  }
  .list-container {
    .list-item {
      color: #8a8f99;
      display: flex;
      padding: 10px 5px;
      align-items: center;
      cursor: pointer;
      .item-icon {
        padding: 0px 10px;
      }
      .item-text {
        flex-grow: 1;
      }
      .item-num {
        .item-num-span {
          color: #fff;
          padding: 3px 15px;
          background: #bdc0c7;
          border-radius: 11px;
        }
      }
    }
    .active-item {
      color: #202126;
      .item-num {
        .item-num-span {
          background: #525966;
        }
      }
    }
  }
}
</style>

<style lang="less">
.header-search {
  width: 220px !important;
  .search {
    .el-input__inner {
      border-radius: 6px;
      background: #f2f3f5;
    }
  }
}
</style>